import React, { Component } from "react";
import CanvasJSReact from "../assets/canvasjs.react";
import Navbar from "./Navbar_container";
import { domain } from "../helpers/route_helper";
import TagVisualizer from './TagVisualizer'
import TicketVisualizer from './TicketVisualizer'
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class Visualize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      loans1: "",
      date1: "",
      name1: "1",
      color1: "",
      loading1: false,
      normalize1: false,
      predict1: false,
      combine1: false,
      freq: null,
      num: 1,
      inquiryData: {},
      chartType: 'vintage'
    };

    this.colors = ["pink", "black", "blue", "green", "orange", "grey", "purple", "red", "brown"]
  }

  reset = () => {
    const newState = { num: 1 };
    for (let i = 1; i <= this.state.num; i++) {
      newState[`loans${i}`] = "";
      newState[`date${i}`] = "";
      newState[`name${i}`] = "1";
      newState[`color${i}`] = "";
      newState[`data${i}`] = {};
      newState[`normalize${i}`] = false;
      newState[`predict${i}`] = false;
      newState[`combine${i}`] = false;
      newState[`loading${i}`] = false;
    }
    this.setState(newState);
  };


  fetchData = (num = 1) => e => {
    // if (this.state[`loans${num}`].length > 10000) {
    //   alert("Too many loans in query. The internet won't allow it!");
    //   return;
    // }

    // set default name for lines or else creating multiple will be a problem!
    this.setState({ [`loading${num}`]: true });
    window.$
      .ajax({
        method: "POST",
        url: `${domain}/internal/api/performance`,
        data: { loans: this.state[`loans${num}`], date: this.state[`date${num}`], normalize: this.state[`normalize${num}`], predict: this.state[`predict${num}`], combine: this.state[`combine${num}`] }
      })
      .then(data => this.setState((state) => {
          return({
            [`data${num}`]: data,
            [`name${num}`]: state[`name${num}`] || num.toString(),
            [`color${num}`]: this.colors[num],
            [`loading${num}`]: false
          })
        },
        errors => this.setState({[`loading${num}`]: false})
      )
    );
  };

  componentDidMount() {
    const {chart} = this.props.match.params
    this.setState({chartType: chart})
  }

  componentDidUpdate(prevProps) {
    const {chart} = this.props.match.params
    const prevChart = prevProps.match.params.chart
    if (chart !== prevChart) {
      this.setState({chartType: chart})
    }
  }

  render() {
    const data = [];
    for (let i = 1; i <= this.state.num; i++) {
      let dataPoints = {};
      for (let key in this.state[`data${i}`]) {
        for (let freq in this.state[`data${i}`][key]) {
          if (this.state.freq && !this.state.freq.includes(freq)) {
            continue;
          }
          if (!dataPoints[freq]) {
            dataPoints[freq] = [];
          }
          dataPoints[freq].push({
            x: key,
            y: this.state[`data${i}`][key][freq]["return"],
            toolTipContent: `{x}: {y} (n=${this.state[`data${i}`][key][freq]["n"]})`
          })
        }
      }
      const lineTypes = {"weekly": "dot", "biweekly": "dash", "monthly": "solid", "30": "dashDot"};

      let show = true;
      for (let freq in dataPoints) {
        data.push({
          yValueFormatString: "#",
          xValueFormatString: "#",
          type: "spline",
          lineDashType: lineTypes[freq],
          dataPoints: dataPoints[freq],
          color: this.state[`color${i}`],
          showInLegend: show,
          markerSize: 2,
          name: this.state[`name${i}`]
        });
        show = false;
      }

    }
    const options = {
      animationEnabled: true,
      exportEnabled: true,
      height: 500,
      title: {
        text: this.state.title || "Vintage Curve"
      },
      axisX: {
        valueFormatString: "#",
        title: "Weeks Since Funded",
        maximum: 36,
        interval: 1
      },
      axisY: {
        title: "Principal",
        suffix: "%",
        includeZero: true,
        maximum: 150,
        interval: 25
      },
      data
    };
    const inputs = [];
    for (let i = 1; i <= this.state.num; i++) {
      inputs.push(
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 100,
            width: 200,
            height: 300,
            marginTop: 20
          }}
        >
          <textarea
            value={this.state[`loans${i}`]}
            onChange={e => this.setState({ [`loans${i}`]: e.target.value })}
            style={{ height: 100, marginBottom: 10 }}
            placeholder="Insert loans here, comma separated"
          />
          <input
            type="date"
            value={this.state[`date${i}`]}
            style={{ marginBottom: 10 }}
            onChange={e => this.setState({ [`date${i}`]: e.target.value })}
            placeholder="date"
          />
          <input
            type="text"
            value={this.state[`name${i}`]}
            style={{ marginBottom: 10 }}
            onChange={e => this.setState({ [`name${i}`]: e.target.value })}
            placeholder="name"
          />
          <input
            type="text"
            value={this.state[`color${i}`]}
            onChange={e => this.setState({ [`color${i}`]: e.target.value })}
            placeholder="color"
          />
          <label htmlFor="normalize">
            <input
              name="normalize"
              type="checkbox"
              checked={this.state[`normalize${i}`]}
              onChange={() => this.setState({ [`normalize${i}`]: !this.state[`normalize${i}`] })}
            />
            normalize
          </label>
          <label htmlFor="predict">
            <input
              name="predict"
              type="checkbox"
              checked={this.state[`predict${i}`]}
              onChange={() => this.setState({ [`predict${i}`]: !this.state[`predict${i}`] })}
            />
            predict
          </label>
          <label htmlFor="combine">
            <input
              name="combine"
              type="checkbox"
              checked={this.state[`combine${i}`]}
              onChange={() => this.setState({ [`combine${i}`]: !this.state[`combine${i}`] })}
            />
            combine
          </label>
          {this.state[`loading${i}`] ? (
            <span>*</span>
          ) : (
            <button onClick={this.fetchData(i)}>Submit</button>
          )}
        </div>
      );
    }
    let chartComp
    switch (this.state.chartType){
      case 'vintage':
        chartComp = (
          <div>
          <div className="chart-container">
            <CanvasJSChart
            options={options}
            /* onRef={ref => this.chart = ref} */
            />
          </div>
          <div className="chart-options">
            <input
              type="text"
              value={this.state.title}
              onChange={e => this.setState({ title: e.target.value })}
              placeholder="Title"
            />
            <button onClick={() => this.setState({ num: this.state.num + 1 })}>
              Add Line
            </button>
            <button onClick={this.reset}>Reset</button>
            <input
              type="text"
              value={this.state.freq}
              onChange={e => this.setState({ freq: e.target.value })}
              placeholder="Frequency"
            />

          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>{inputs}</div>
          </div>
        )
        break;
      case 'tag':
        chartComp = (
          <TagVisualizer />
        )
        break;
      case 'inquiry':
        chartComp = (
          <TagVisualizer data={this.state.inquiryData} />
        )
        break;
      case 'cs':
        chartComp = (
          <TicketVisualizer />
        )
        break;
      default:
        chartComp = null;
    }

    return (
      <div>
        <Navbar />
          {chartComp}
      </div>
    );
  }
}
