import {
  RECEIVE_UNDERWRITING_DECISIONS,
  UPDATE_UNDERWRITING_DECISION,
  COMPLETE_UNDERWRITING_DECISION,
  EDIT_UNDERWRITING_DECISION
} from "../actions/underwriting_actions";

const underwritingDecisionsReducer = (
  oldState = { manual_decisions: {}, normal_decisions: {}, clarity_loans: {} },
  action
) => {
  Object.freeze(oldState);
  let newState;
  const oldStateKeys = Object.keys(oldState);
  switch (action.type) {
    case RECEIVE_UNDERWRITING_DECISIONS:
      newState = Object.assign({}, oldState, action.underwritingDecisions);
      return newState;
    case UPDATE_UNDERWRITING_DECISION:
      newState = Object.assign({}, oldState, {});
      const decisionId = Object.keys(action.underwritingDecision)[0];
      const underwritingDecision = action.underwritingDecision[decisionId];
      oldStateKeys.forEach(key => {
        if (newState[key][decisionId]) {
          newState[key][decisionId] = underwritingDecision;
        } else if (newState[key][action.underwritingDecision.id]) {
          newState[key][action.underwritingDecision.id] =
            action.underwritingDecision[action.underwritingDecision.id];
        }
      });
      return newState;
    case EDIT_UNDERWRITING_DECISION:
      newState = Object.assign({}, oldState, {});
      const editDecisionId = Object.keys(action.underwritingDecision)[0];
      const editedAttr = action.editedAttr;
      oldStateKeys.forEach(key => {
        if (newState[key][editDecisionId]) {
          newState[key][editDecisionId][editedAttr] =
            action.underwritingDecision[action.underwritingDecision.id][
              editedAttr
            ];
        }
      });
      return newState;
    case COMPLETE_UNDERWRITING_DECISION:
      newState = Object.assign({}, oldState, {});
      oldStateKeys.forEach(key => {
        delete newState[key][action.id];
      });
      return newState;
    default:
      return oldState;
  }
};

export default underwritingDecisionsReducer;
