import React, { useState } from "react";
import { connect } from "react-redux";
import {
  updatePaymentDate,
  cancelEarlyRepay,
  togglePaymentMethod,
  smallerPayment,
  creditBounceFee,
} from "../../../../actions/ticket_actions";
import { appeal } from "../../../../actions/loan_actions";
import { formatJSONDate, prettifyDateLong } from "../../../../helpers/date_helper";
import { formatPayments } from "../../../../helpers/ticket_helper";
import Loading from "react-loader-spinner";
import DatePicker from "react-datepicker";
import moment from "moment";
import { motion } from "framer-motion";
import DebouncedButton from "../../../DebouncedButton";

const Payment = ({
  id,
  pmt,
  loan,
  updatePaymentDate,
  cancelEarlyRepay,
  togglePaymentMethod,
  smallerPayment,
  creditBounceFee,
  macros,
  setReplyMessage,
  setMacroId,
  ticketDetails
}) => {
  const [loading, setLoading] = useState(false);
  const [expandPayment, setExpandPayment] = useState(false);
  const [customAmt, setCustomAmt] = useState("");
  const [splitPayment, setSplitPayment] = useState(false);
  const [paymentUpdating, setPaymentUpdating] = useState(false);

  const createReply = (expectedMacro) => {
    let replyMessage = null;
    const loanDetails = ticketDetails[id].loans[loan.id]
    const pmtDetails = loanDetails.additional.payments[pmt.id]
    let macroAlert =
      "The payment has been updated but dashboard couldn't pre-fill reply with macro *. Please screenshot this error message and reach out to Internal Tools.";
    const matchRegex = /_/g;
    let matchIdx = 0;
    if (expectedMacro === "custom_payment" && macros[expectedMacro]) {
      const firstMatch = `$${pmtDetails.expected_amt}`;
      const secondMatch = prettifySchedule(loanDetails);
      replyMessage = macros[expectedMacro].text.replace(matchRegex, match => matchIdx++ === 0 ? firstMatch : secondMatch);
    } else if (expectedMacro === "change_payment_date" && macros[expectedMacro]) {
      const firstMatch = prettifyDateLong(pmtDetails.due.toString());
      const secondMatch = prettifySchedule(loanDetails);
      replyMessage = macros[expectedMacro].text.replace(matchRegex, match => matchIdx++ === 0 ? firstMatch : secondMatch);
    } else if (expectedMacro === "update_payment_method" && macros[expectedMacro]) {
      replyMessage = macros[expectedMacro].text.replace(matchRegex, prettifyDateLong(pmt.due));
    } else if (expectedMacro === "cancel_early_repay" && macros[expectedMacro]) {
      replyMessage = macros[expectedMacro].text.replace(matchRegex, pmt.expected_amt);
    } else if (expectedMacro === "handle_credit_bounce" && macros[expectedMacro]) {
      replyMessage = macros[expectedMacro].text;
    } else {
      replyMessage = null;
    }
    let macroId = macros[expectedMacro] ? macros[expectedMacro].id : null;
    setMacroId(macroId);
    setReplyMessage(replyMessage);
    if (replyMessage === null) {
      alert(macroAlert.replace("*", expectedMacro));
    }
  };

  const prettifySchedule = (loanResp) => {
    return formatPayments(Object.values(loanResp.additional.payments).filter((p) => !p.paid));
  };
  
  const handleCustom = (split = false) => {
    setLoading(true);
    smallerPayment(id, pmt.id, split ? "split" : customAmt).then(() => {
      const expectedMacro = "custom_payment"
      createReply(expectedMacro);
      setLoading(false);
    });
  };

  const handleUpdate = (e) => {
    setPaymentUpdating(true);
    updatePaymentDate(id, pmt.id, e.toString()).then(() => {
      const expectedMacro = "change_payment_date";
      createReply(expectedMacro);
      setPaymentUpdating(false);
    });
  };

  const handleToggle = () => {
    setLoading(true);
    togglePaymentMethod(id, pmt.id).then(() => {
      const expectedMacro = "update_payment_method";
      createReply(expectedMacro);
      setLoading(false);
    });
  };

  const handleCancel = () => {
    setLoading(true);
    cancelEarlyRepay(id, pmt.id).then(() => {
      const expectedMacro = "cancel_early_repay";
      createReply(expectedMacro);
      setLoading(false);
    });
  };

  const handleCreditBounce = () => {
    setLoading(true);
    creditBounceFee(id, pmt.id).then(() => {
      const expectedMacro = "handle_credit_bounce";
      createReply(expectedMacro);
      setLoading(false);
    });
  };

  const original_due = new Date(formatJSONDate(pmt.original_due));
  const lateDate = new Date(original_due.setDate(original_due.getDate() + 29));

  return (
    <motion.li
      className={
        pmt.processing
          ? `loan-payment processing`
          : pmt.paid
          ? "loan-payment paid"
          : "loan-payment"
      }
      style={{ position: "relative" }}
    >
      {pmt?.paid && <p style={{ fontWeight: "bold" }}>PAID</p>}
      {pmt?.balance_check_fail && (
        <p
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            fontSize: "12px",
            color: "white",
            background: "red",
            borderRadius: "10px",
            padding: "4px",
          }}
        >
          Balance Check Fail
        </p>
      )}
      {pmt?.low_probability_payment && (
        <p
          style={{
            position: "absolute",
            top: pmt.balance_check_fail ? "40px" : "10px",
            left: "10px",
            fontSize: "12px",
            color: "black",
            background: "yellow",
            borderRadius: "10px",
            padding: "4px",
          }}
        >
          Low Probability
        </p>
      )}
      {pmt?.user_initiated && (
        <p
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "12px",
            color: "white",
            background: "#324D5D",
            borderRadius: "10px",
            padding: "4px",
          }}
        >
          User Initiated
        </p>
      )}
      <p
        onClick={() => setExpandPayment((s) => !s)}
        className="li-payment-amount"
      >
        ${pmt.expected_amt}
        <br />
        {moment.utc(pmt.due).format("MM-DD-YY")}
      </p>
      {expandPayment && !loading && (
        <>
          <p className="li-payment-id">
            <span style={{ fontWeight: "bold" }}>Payment ID: </span>
            {pmt.id}
          </p>

          <p>
            <span style={{ fontWeight: "bold" }}>Late On: </span>
            {moment.utc(lateDate).format("MM-DD-YY")}
          </p>

          {pmt.bounces.length > 0 && (
            <p>
              <span style={{ fontWeight: "bold" }}>Most Recent Bounces: </span>
              {pmt.bounces.join(", ")}
            </p>
          )}

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="payment-expanded-info"
          >
            {pmt.bounce_fee > 0 && (
              <div className="payment-control">
                <p>Waive Bounce Fee: </p>

                <DebouncedButton
                  className="payment-button"
                  onClick={handleCreditBounce}
                >
                  Waive Bounce
                </DebouncedButton>
              </div>
            )}

            {!pmt.paid && !pmt.processing && (
              <div className="payment-control">
                <p>Change Payment Date:</p>
                {paymentUpdating && (
                  <Loading
                    type="ThreeDots"
                    color="#41b29e"
                    height={30}
                    width={30}
                  />
                )}
                {!paymentUpdating && (
                  <DatePicker
                    style={{ height: "2rem", width: "80%" }}
                    id="datepicker"
                    onSelect={handleUpdate}
                    selected={
                      Date.parse(pmt.due) < Date.parse(Date())
                        ? moment.utc(Date())
                        : moment.utc(pmt.due).add(2, "w")
                    }
                    filterDate={isWeekday}
                    minDate={moment.utc(new Date())}
                    highlightDates={[moment.utc(lateDate).subtract(1, "d")]}
                  />
                )}
              </div>
            )}
            {pmt.paid ? (
              "Paid"
            ) : pmt.processing ? (
              `Processing${
                pmt.method === "no_pref" ? `: ${pmt.processing_method}` : ""
              }`
            ) : pmt.type === "EarlyRepayment" ? (
              <div className="payment-control">
                <p>Cancel Early Repayment:</p>
                <DebouncedButton
                  className="payment-button"
                  onClick={handleCancel}
                >
                  Cancel
                </DebouncedButton>
              </div>
            ) : null}
            {pmt.paid || pmt.processing ? (
              ` ${pmt.method.toUpperCase()} ${pmt.payment_account || ''}`
            ) : (
              <div className="payment-control">
                <p>Toggle Payment Method:</p>
                <DebouncedButton
                  className="payment-button"
                  onClick={handleToggle}
                >
                  {pmt.method}
                </DebouncedButton>
              </div>
            )}
            {!pmt.paid && !pmt.processing && (
              <>
                <div className="payment-control">
                  <p>Custom Payment Amount:</p>
                  <input
                    type="text"
                    placeholder="0.00"
                    value={customAmt}
                    onChange={(e) => setCustomAmt(e.target.value)}
                  />
                  {customAmt.length > 0 && (
                    <DebouncedButton
                      className="payment-button"
                      onClick={() => handleCustom(false)}
                    >
                      Submit
                    </DebouncedButton>
                  )}
                </div>
                <div className="payment-control">
                  <p>Split Payment</p>
                  {splitPayment && (
                    <p>Original Payment : ${pmt.expected_amt}</p>
                  )}
                  {!splitPayment && (
                    <DebouncedButton
                      className="payment-button"
                      onClick={() => setSplitPayment(true)}
                    >
                      Split Payment
                    </DebouncedButton>
                  )}
                  {splitPayment && (
                    <>
                      {
                        <DebouncedButton
                          className="payment-button"
                          onClick={() => handleCustom(true)}
                        >
                          Submit
                        </DebouncedButton>
                      }
                    </>
                  )}
                </div>
              </>
            )}
          </motion.div>
        </>
      )}
    </motion.li>
  );
};

const isWeekday = (date) => ![0, 6].includes(date.day());

const mapStateToProps = (state) => ({ macros: state.entities.macros,
                                      ticketDetails: state.entities.tickets.tickets});

const mapDispatchToProps = (dispatch) => ({
  cancelEarlyRepay: (id, pmtId) => dispatch(cancelEarlyRepay(id, pmtId)),
  updatePaymentDate: (id, pmtId, date) =>
    dispatch(updatePaymentDate(id, pmtId, date)),
  togglePaymentMethod: (id, pmtId) => dispatch(togglePaymentMethod(id, pmtId)),
  appeal: (loanId) => dispatch(appeal(loanId)),
  smallerPayment: (id, pmtId, amt) => dispatch(smallerPayment(id, pmtId, amt)),
  creditBounceFee: (id, pmtId) => dispatch(creditBounceFee(id, pmtId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
