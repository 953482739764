import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar_container";
import { domain } from "../helpers/route_helper";

const size = {
  width: window.innerWidth * 0.95,
  height: window.innerHeight * 0.8
};

const getMousePosition = event => {
  const CTM = event.target.getScreenCTM();

  return {
    x: (event.clientX - CTM.e) / CTM.a,
    y: (event.clientY - CTM.f) / CTM.d
  };
};

const Rect = ({ index, x0, x1, y0, y1, name, value, length, colors }) => {
  return (
    <>
      <rect
        x={x0}
        y={y0}
        width={x1 - x0}
        height={y1 - y0}
        fill={colors(index / length)}
        data-index={index}
      />
      <text
        id={name}
        x={x0 < size.width / 2 ? x1 + 6 : x0 - 6}
        y={(y1 + y0) / 2}
        style={{
          fill: window.d3.rgb(colors(index / length)).darker(),
          alignmentBaseline: "middle",
          fontSize: 14,
          zIndex: 10,
          textAnchor: x0 < size.width / 2 ? "start" : "end",
          pointerEvents: "none",
          userSelect: "none"
        }}
      >
        {`${name}(${value})`}
      </text>
    </>
  );
};

const Link = ({ data, width, length, colors }) => {
  const link = window.d3.sankeyLinkHorizontal();

  return (
    <>
      <defs>
        <linearGradient
          id={`gradient-${data.index}`}
          gradientUnits="userSpaceOnUse"
          x1={data.source.x1}
          x2={data.target.x0}
        >
          <stop offset="0" stopColor={colors(data.source.index / length)} />
          <stop offset="100%" stopColor={colors(data.target.index / length)} />
        </linearGradient>
      </defs>
      <path
        id={`${data.index}-${data.value}`}
        d={link(data)}
        data-value={data.value}
        data-source={data.source.name}
        data-target={data.target.name}
        fill={"none"}
        stroke={`url(#gradient-${data.index})`}
        strokeOpacity={0.5}
        strokeWidth={width}
      />
      <text
        id={`text-${data.index}-${data.value}`}
        style={{ display: "none", color: "white" }}
      >
        <textPath xlinkHref={`#${data.index}-${data.value}`}>
          {data.value} ({Math.round((data.value * 100) / data.source.value)}%)
        </textPath>
      </text>
    </>
  );
};

const Sankey = props => {
  const dragElement = useRef(null);
  const highlightPath = useRef(null);
  const graph = useRef(null);
  const offset = useRef(null);
  useEffect(() => {
    window.addEventListener("mouseup", onMouseUp);
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  const colors = window.d3.interpolateRainbow;
  const sankey = window.d3
    .sankey()
    .nodeAlign(window.d3.sankeyJustify)
    .nodeWidth(10)
    .nodePadding(10)
    .extent([[0, 0], [size.width, size.height]]);

  const onMouseUp = e => {
    dragElement.current = null;
    if (highlightPath.current) {
      highlightPath.current.setAttributeNS(null, "stroke-opacity", 0.5);
      document.getElementById(
        `text-${highlightPath.current.id}`
      ).style.display = "none";
      const sourceText = document.getElementById(
        highlightPath.current.dataset.source
      );
      sourceText.style.textDecorationLine = "none";
      const targetText = document.getElementById(
        highlightPath.current.dataset.target
      );
      targetText.style.textDecorationLine = "none";
      highlightPath.current = null;
    }
  };

  const onMouseDown = e => {
    if (e.target.tagName === "rect") {
      dragElement.current = e.target;
      offset.current = getMousePosition(e);
      offset.current.y -= parseFloat(e.target.getAttributeNS(null, "y"));
    } else if (e.target.tagName === "path") {
      highlightPath.current = e.target;
      highlightPath.current.setAttributeNS(null, "stroke-opacity", 1.0);
      document.getElementById(
        `text-${highlightPath.current.id}`
      ).style.display = "";
      const sourceText = document.getElementById(
        highlightPath.current.dataset.source
      );
      sourceText.style.textDecorationLine = "underline";
      const targetText = document.getElementById(
        highlightPath.current.dataset.target
      );
      targetText.style.textDecorationLine = "underline";
    }
  };

  const onMouseMove = e => {
    if (dragElement.current) {
      const coord = getMousePosition(e);
      dragElement.current.setAttributeNS(null, "y", coord.y - offset.current.y);
    }
  };

  if (!props.loading && props.data && props.data.links.length > 0) {
    graph.current = sankey(props.data);
    const { links, nodes } = graph.current;

    return (
      <svg
        width={size.width}
        height={size.height}
        style={{ backgroundColor: "white" }}
      >
        <g>
          {links.map((d, i) => (
            <Link
              data={d}
              width={d.width}
              length={nodes.length}
              colors={colors}
              key={i}
            />
          ))}
        </g>
        <g>
          {nodes.map((d, i) => (
            <Rect
              index={d.index}
              x0={d.x0}
              x1={d.x1}
              y0={d.y0}
              y1={d.y1}
              name={d.name}
              value={d.value}
              length={nodes.length}
              colors={colors}
              key={i}
            />
          ))}
        </g>
      </svg>
    );
  }

  return <div>{props.loading ? "Loading" : "No data to show"}</div>;
};

const loadScripts = use => {
  let done = 0;
  const sources = [
    "https://d3js.org/d3.v5.min.js",
    "https://unpkg.com/d3-array@1",
    "https://unpkg.com/d3-collection@1",
    "https://unpkg.com/d3-path@1",
    "https://unpkg.com/d3-shape@1",
    "https://unpkg.com/d3-sankey@0"
  ];
  sources.forEach(source => {
    let script = document.createElement("script");
    script.src = source;
    script.onload = () => {
      done += 1;
      if (done === sources.length) {
        use(done);
      }
    };
    document.body.appendChild(script);
  });
};

const Flow = () => {
  const [data, setData] = useState({
    links: [
      { value: 99, source: 0, target: 13 },
      { value: 35, source: 0, target: 19 },
      { value: 4, source: 0, target: 20 },
      { value: 2, source: 0, target: 21 },
      { value: 101, source: 1, target: 17 },
      { value: 5, source: 1, target: 19 },
      { value: 46, source: 2, target: 11 },
      { value: 16, source: 2, target: 15 },
      { value: 105, source: 2, target: 16 },
      { value: 42, source: 3, target: 6 },
      { value: 22, source: 3, target: 19 },
      { value: 4, source: 4, target: 16 },
      { value: 43, source: 5, target: 3 },
      { value: 696, source: 5, target: 14 },
      { value: 8, source: 6, target: 13 },
      { value: 8, source: 6, target: 22 },
      { value: 6, source: 6, target: 19 },
      { value: 31, source: 6, target: 20 },
      { value: 140, source: 7, target: 0 },
      { value: 27, source: 7, target: 19 },
      { value: 106, source: 8, target: 1 },
      { value: 34, source: 8, target: 12 },
      { value: 284, source: 8, target: 17 },
      { value: 7, source: 9, target: 3 },
      { value: 13, source: 9, target: 22 },
      { value: 60, source: 9, target: 19 },
      { value: 739, source: 10, target: 5 },
      { value: 45, source: 11, target: 16 },
      { value: 1, source: 11, target: 19 },
      { value: 34, source: 12, target: 17 },
      { value: 166, source: 13, target: 2 },
      { value: 7, source: 13, target: 18 },
      { value: 22, source: 13, target: 19 },
      { value: 424, source: 14, target: 8 },
      { value: 80, source: 14, target: 9 },
      { value: 192, source: 14, target: 19 },
      { value: 4, source: 15, target: 4 },
      { value: 12, source: 15, target: 16 },
      { value: 165, source: 16, target: 22 },
      { value: 1, source: 16, target: 21 },
      { value: 10, source: 17, target: 3 },
      { value: 167, source: 17, target: 7 },
      { value: 88, source: 17, target: 13 },
      { value: 154, source: 17, target: 19 },
      { value: 1, source: 18, target: 2 },
      { value: 4, source: 18, target: 19 },
      { value: 2, source: 18, target: 20 }
    ],
    nodes: [
      { name: "TradStage2ManualReview" },
      { name: "OhFlaChecksEarly" },
      { name: "RegRegimeRoutingLate" },
      { name: "TradManualStatementClarityGate" },
      { name: "IlIntManualPaystubs" },
      { name: "TradStage0" },
      { name: "TradManualStatements" },
      { name: "TradManualReviewClarityGate" },
      { name: "RegRegimeRoutingEarly" },
      { name: "SecStage3Dirty" },
      { name: "BaseStart" },
      { name: "OhFlaChecksLate" },
      { name: "IlIntChecksEarly" },
      { name: "TradStage3Clean" },
      { name: "TradStage1" },
      { name: "IlIntChecksLate" },
      { name: "ApprovalReview" },
      { name: "TradStage2Dirty" },
      { name: "FactaAlert" },
      { name: "Denial" },
      { name: "Pending" },
      { name: "Cancelled" },
      { name: "Approval" }
    ]
  });
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState("");
  const [scriptsLoaded, setScriptsLoaded] = useState(0);

  const fetchData = () => {
    setLoading(true);
    window.$.ajax({
      method: "POST",
      url: `${domain}/internal/api/funnel`,
      data: { loans }
    }).then(data => {
      setData(data);
      setLoading(false);
    });
  };

  if (!window.d3) {
    loadScripts(setScriptsLoaded);
    return <div />;
  }
  return (
    <div>
      <Navbar />
      <div>
        <textarea value={loans} onChange={e => setLoans(e.target.value)} />
        <div>
          <button onClick={() => fetchData(loans)}>Fetch</button>
        </div>
        <Sankey data={data} loading={loading} />
      </div>
    </div>
  );
};

export default Flow;
