import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getTicket } from "../../actions/ticket_actions";
import { fetchMacros } from "../../actions/macro_actions";
import Modal from "../Modal";
import Escalate from "./ticket/Escalate";
import ArchiveClose from "./ticket/ArchiveClose";
import Communications from "./ticket/Communications";
import Reply from "./ticket/Reply";
import Analysis from "./ticket/Analysis";
import PersonalInfo from "./ticket/PersonalInfo";
import LoanInfo from "./ticket/LoanInfo";
import Stats from "./TicketStats";
import Errors from "./ticket/Errors";
import ViewDocuments from "../dashboard/ViewDocuments";
import LinkAccount from "./ticket/LinkAccount";
import { motion } from "framer-motion";

const Ticket = ({ tickets, id, submit, getTicket, ticketErrors, macros }) => {
  const ticket = tickets[id];
  const errors = ticketErrors[id];

  const [showModal, setModal] = useState(false);
  const [modalComponent, setModalComponent] = useState(null);
  const [responses, setResponses] = useState(new Set());
  const [fetched, setFetched] = useState(false);
  const [sent, setSent] = useState(new Set());

  // Reply Message for Reply Component. Kept here to store and clear message when archiving / unarchiving tickets
  const [replyMessage, setReplyMessage] = useState("");
  // macroId represents the id of the Sydney::Template used by the customer service agent
  // in their reply to the customer. Macros are selected manually by customer service agents
  const [macroId, setMacroId] = useState();
  // filterId represents the id of the Sydney::Filter that is suggested on a ticket. The body of text
  // lives in the guesses object in the details field of the ticekt object. This is only stored
  // when a customer service agent uses a suggested response to respond to a ticket
  const [filterId, setFilterId] = useState();

  useEffect(() => {
    if (replyMessage == null || replyMessage == "") {
      setMacroId(null);
      setFilterId(null);
    }
  }, [replyMessage]);

  useEffect(() => {
    if (macroId !== null) {
      setFilterId(null);
    }
  }, [macroId]);

  useEffect(() => {
    if (filterId !== null) {
      setMacroId(null);
    }
  }, [filterId]);

  // fetch macros if not loaded
  useEffect(() => {
    if (Object.keys(macros).length === 0) {
      fetchMacros();
    }
  }, [macros]);

  const handleShowModal = (comp, height) => {
    setModal(true);
    setModalComponent(comp);
  };

  const handleCloseModal = () => {
    setModalComponent(null);
    setModal(false);
  };

  const handleSent = reply => {
    setSent(new Set(sent.add(reply)));
  };

  useEffect(() => {
    if (!ticket || !ticket.analysis || !fetched) {
      setResponses(new Set());
      getTicket(id).then(() => setFetched(true));
    } else if (ticket && ticket.details && ticket.details.guesses) {
      setResponses(
        new Set(ticket.details.guesses.map(g => [g.response, g.action]))
      );
    }
  }, [ticket]);

  if (!ticket || !ticket.analysis || !fetched) {
    return <div className="ticket" />;
  }

  const addResponse = response =>
    setResponses(new Set(responses.add(response)));

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="ticket"
    >
      <Errors errors={errors} />
      <Escalate submit={submit} id={id} />
      <ViewDocuments
        user_id={ticket.user_id}
        handleShowModal={handleShowModal}
        handleCloseModal={handleCloseModal}
      />
      <ArchiveClose
        setReplyMessage={setReplyMessage}
        replyMessage={replyMessage}
        submit={submit}
        id={id}
      />
      {ticket.details ? (
        <Communications
          ticket={ticket.details}
          handleShowModal={handleShowModal}
          sent={Array.from(sent)}
        />
      ) : (
        <div className="communications">
          No details to show. Processing must have failed. Please look at user
          page for inquiry.
        </div>
      )}
      <Reply
        id={id}
        responses={responses}
        replyMessage={replyMessage}
        setReplyMessage={setReplyMessage}
        macroId={macroId}
        setMacroId={setMacroId}
        filterId={filterId}
        setFilterId={setFilterId}
        handleSent={handleSent}
        submit={submit}
        userName={ticket.user ? ticket.user.first_name : ""}
      />
      <Analysis analysis={ticket.analysis} />
      <LoanInfo
        id={id}
        loanInfo={ticket.loans || {}}
        addResponse={addResponse}
        setReplyMessage={setReplyMessage}
        setMacroId={setMacroId}
      />
      {ticket.user ? (
        <PersonalInfo
          id={id}
          userInfo={ticket.user}
          ticket={ticket.details}
          addResponse={addResponse}
          setReplyMessage={setReplyMessage}
          macros={macros}
          setMacroId={setMacroId}
        />
      ) : (
        <LinkAccount
          email={ticket.details ? ticket.details.email : "Couldn't load email"}
          id={id}
          setModal={setModal}
          setModalComponent={setModalComponent}
        />
      )}
      {showModal && (
        <Modal modal_class={"standard_modal"} closeAction={handleCloseModal}>
          {modalComponent}
        </Modal>
      )}
    </motion.div>
  );
};

const mapStateToProps = state => ({
  tickets: state.entities.tickets.tickets,
  ticketErrors: state.entities.tickets.ticketErrors,
  closedTickets: state.entities.tickets.closedTickets,
  macros: state.entities.macros
});

const mapDispatchToProps = dispatch => ({
  getTicket: id => dispatch(getTicket(id)),
  fetchMacros: () => dispatch(fetchMacros())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ticket);
