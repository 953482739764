import { domain } from "../helpers/route_helper";

export const fetchUnderwritingDecisions = () => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/underwriting/dashboard`,
    data: {}
  });
};

export const editLoan = (id, key, val) => {
  return window.$.ajax({
    method: "post",
    url: `${domain}/internal/api/underwriting/edit_loan`,
    data: { id, key, val }
  });
};

export const expandDecision = (id, id_type) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/underwriting/expand_decision`,
    data: { id, id_type }
  });
};

export const expandPlaid = (id, id_type) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/underwriting/load_plaid_data`,
    data: { id, id_type }
  });
};

export const expandPlaidTransactions = (id) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/plaid_classification`,
    data: { id }
  });
};

export const submitPlaidClassification = (plaid_id, classification_type, classification) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/plaid_classification`,
    data: { plaid_id, classification_type, classification }
  });
};

export const loanAction = (id, loan_action, comment, amount, experiment) => {
  return window.$.ajax({
    method: "post",
    url: `${domain}/internal/api/underwriting/loan_action`,
    data: { id, loan_action, comment, amount, experiment }
  });
};
