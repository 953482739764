import React, { Component } from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { editLoan, loanAction } from "../actions/underwriting_actions";
import UnderwritingLoanData from "./UnderwritingLoanData";

class UnprocessedDecision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      denyLoading: false,
      approveLoading: false,
      manualsLoading: false,
      comment: this.props.decision.comment,
      amount: this.props.decision.amount,
      experiment: this.props.decision.experiment,
    };
  }

  handleExpansion = () => {
    this.setState({
      expanded: true,
    });
    this.props.backgroundLoad(this.props.decision.id);
  };

  closeExpansion = () => {
    this.setState({
      expanded: false,
    });
  };

  onError = (key) => {
    this.setState({ [key]: this.props.decision[key] });
  };

  componentDidMount() {
    console.log(this.props.index);
  }

  handleAction = (action) => {
    let callback;
    switch (action) {
      case "Deny":
        this.setState({ denyLoading: true });
        callback = () => {
          this.setState({ denyLoading: false });
        };
        break;
      case "Approve":
        this.setState({ approveLoading: true });
        callback = () => {
          this.setState({ approveLoading: false });
        };
        break;
      case "Manuals":
        this.setState({ manualsLoading: true });
        callback = () => {
          this.setState({ manualsLoading: false });
        };
        break;
      default:
    }
    this.props.loanAction(
      this.props.decision.loan_id,
      action,
      this.state.comment,
      this.state.amount,
      this.state.experiment,
      this.props.decision.id,
      callback
    );
  };

  handleEdit = (key, val) => {
    this.props.editLoan(this.props.decision.loan_id, key, val, this.onError);
  };

  spinnerOptions = {
    lines: 10,
    length: 5,
    width: 3,
    radius: 3,
    top: "12.5px",
    position: "relative",
    color: "#41b29e",
  };

  componentDidMount() {}

  render() {
    const spec_class =
      this.props.decision.type === "CreditBuilder"
        ? "credit_builder"
        : this.props.decision.experiment &&
          this.props.decision.experiment.includes("repeat_recovery")
        ? "repeat_recovery"
        : "";

    return (
      <div className={`loan ${this.props.index % 2 === 0 ? "lightgrey" : ""}`}>
        <div class="summary">
          <div
            class="id cell id_btn"
            onClick={() => {
              this.handleExpansion();
            }}
          >
            {this.props.decision.loan_id}
          </div>
          <div class="name cell">{this.props.decision.user_name}</div>
          <div class="amount cell">
            ${this.props.decision.amount} ({this.props.decision.applied_amt})
          </div>
          <div class="applied cell">{this.props.decision.applied}</div>
          <div id={`decision_${this.props.decision.loan_id}`}>
            <div id={`buttons_${this.props.decision.loan_id}`}>
              <div class="edit_loan">
                <div class="comment cell">
                  <input
                    value={this.state.comment}
                    onChange={(e) => this.setState({ comment: e.target.value })}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.handleEdit("comment", this.state.comment);
                      }
                    }}
                  />
                </div>
                <div class="action cell">
                  <Loader
                    loaded={!this.state.denyLoading}
                    options={this.spinnerOptions}
                  >
                    <div class="btn" onClick={() => this.handleAction("Deny")}>
                      Deny
                    </div>
                  </Loader>
                </div>
                <div class="action cell">
                  <Loader
                    loaded={!this.state.approveLoading}
                    options={this.spinnerOptions}
                  >
                    <div
                      class="btn"
                      onClick={() => this.handleAction("Approve")}
                    >
                      Approve
                    </div>
                  </Loader>
                </div>
                <div class="action cell">
                  <Loader
                    loaded={!this.state.manualsLoading}
                    options={this.spinnerOptions}
                  >
                    <div
                      class="btn"
                      onClick={() => this.handleAction("Manuals")}
                    >
                      Manuals
                    </div>
                  </Loader>
                </div>
                <div class="counter cell">
                  <input
                    value={this.state.amount}
                    onChange={(e) => this.setState({ amount: e.target.value })}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.handleEdit("amount", this.state.amount);
                      }
                    }}
                  />
                </div>
                <div class="experiment cell">
                  <input
                    value={this.state.experiment}
                    onChange={(e) =>
                      this.setState({ experiment: e.target.value })
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.handleEdit("experiment", this.state.experiment);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
        {this.state.expanded ? (
          <div>
            <UnderwritingLoanData
              decision={this.props.decision}
              closeExpansion={this.closeExpansion}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  editLoan: (id, val, key, onError) =>
    dispatch(editLoan(id, val, key, onError)),
  loanAction: (
    loan_id,
    action,
    comment,
    amount,
    experiment,
    decision_id,
    callback
  ) =>
    dispatch(
      loanAction(
        loan_id,
        action,
        comment,
        amount,
        experiment,
        decision_id,
        callback
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnprocessedDecision);
