import { merge } from "lodash";
import { RECEIVE_USER } from "../actions/user_actions";
import { RECEIVE_TEXTS } from "../actions/text_actions";

const defaultProps = {
  loan_ids: []
};

const usersReducer = (oldState = defaultProps, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_USER:
      return merge({}, action.user);
    case RECEIVE_TEXTS:
      return merge({}, action.payload.user);
    default:
      return oldState;
  }
};

export default usersReducer;
