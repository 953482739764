import React, { useEffect } from "react";
import * as d3 from "d3";
import moment from "moment";

export default function BalanceGraph(props) {
  useEffect(() => {
    cleanupChart();
    renderChart();
    return () => {
      cleanupChart();
    };
  });

  const cleanupChart = () => {
    var chartSVG = d3.select(`#svg-bal-graph-${props.application.id}`);
    chartSVG.selectAll("*").remove();
  };

  const renderChart = () => {
    var chartSVG = d3.select(`#svg-bal-graph-${props.application.id}`);
    const margin = { top: 50, right: 50, bottom: 50, left: 50 };
    const height = parseInt(chartSVG.style("height"));
    const width = parseInt(chartSVG.style("width"));

    // date -> x coordinate mapping function
    var xScale = d3
      .scaleTime()
      .domain(d3.extent(props.plaid.transactions, d => new Date(d.date)))
      .range([margin.left, width - margin.right]);

    // calculate aggregated transactions
    let agg_trans = {};
    props.plaid.transactions.forEach(x => {
      if (!agg_trans[x.date]) {
        agg_trans[x.date] = x;
      }
    });
    agg_trans = Object.values(agg_trans);

    // account_balance -> y coordinate mapping function
    var yScale = d3
      .scaleLinear()
      .domain(
        d3
          .extent(agg_trans, d => d.account_balance)
          .map((x, i) => {
            // set lower y bound to -500 if min is greater than -500
            if (i === 0 && x > -500) {
              return -500;
            }
            // set upper y bound to 500 if max is less than 500
            if (i === 1 && x < 500) {
              return 500;
            }

            // if not then scale up min and max values by 20% for spacing purposes
            // note this is only changing the scale of the y axis and not actually changing any values
            return x * 1.2;
          })
      )
      .range([height - margin.bottom, margin.top]);

    // line generator function
    var line = d3
      .line()
      // .curve(d3.curveCardinal.tension(0.5))
      .curve(d3.curveBasis)
      .x(d => xScale(new Date(d.date)))
      .y(d => yScale(d.account_balance));

    // x grid line
    chartSVG
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0," + (height - margin.top) + ")")
      .call(
        d3
          .axisBottom(xScale)
          .ticks()
          .tickSize(-height + margin.top + margin.bottom)
          .tickFormat("")
      );

    // y grid line
    chartSVG
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(${margin.right}, 0)`)
      .call(
        d3
          .axisLeft(yScale)
          .ticks()
          .tickSize(-width + margin.right)
          .tickFormat("")
      );

    // draw line through data points
    chartSVG
      .append("path")
      .attr("d", line(agg_trans))
      .attr("fill", "none")
      .attr("stroke", "#41b29e")
      .attr("stroke-width", 2.5);

    let deposits = props.plaid.transactions.filter(
      x => x.trans_line === "green"
    );

    deposits.forEach(deposit => {
      chartSVG
        .append("line")
        .style("stroke", "black")
        .attr("x1", xScale(new Date(deposit.date)))
        .attr("x2", xScale(new Date(deposit.date)))
        .attr("y1", yScale(0))
        .attr("y2", yScale(deposit.amount))
        .attr("stroke-width", 1.5);
    });

    let warnings = props.plaid.transactions.filter(
      x => x.trans_line === "payment_warning"
    );

    warnings.forEach(warning => {
      chartSVG
        .append("line")
        .style("stroke", "red")
        .attr("x1", xScale(new Date(warning.date)))
        .attr("x2", xScale(new Date(warning.date)))
        .attr("y1", yScale(0))
        .attr("y2", yScale(warning.amount))
        .attr("stroke-width", 2);
    });

    let figFundings = props.plaid.transactions.filter(
      x => x.trans_line === "funding_warning"
    );

    figFundings.forEach(funding => {
      chartSVG
        .append("line")
        .style("stroke", "#f0ca3e")
        .attr("x1", xScale(new Date(funding.date)))
        .attr("x2", xScale(new Date(funding.date)))
        .attr("y1", yScale(0))
        .attr("y2", yScale(funding.amount))
        .attr("stroke-width", 2);
    });

    let yAxis = chartSVG
      .append("g")
      .attr("class", "y-axis")
      .attr("transform", `translate(${margin.right}, 0)`)
      .call(d3.axisLeft(yScale).tickFormat(d => `$${d}`));

    yAxis.select("path").remove();
    yAxis
      .selectAll(".tick")
      .selectAll("line")
      .remove();

    let xAxis = chartSVG
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", "translate(0, 450)")
      .call(
        d3.axisBottom(xScale).tickFormat(function(date) {
          if (d3.timeYear(date) < date) {
            return d3.timeFormat("%b")(date);
          } else {
            return d3.timeFormat("%Y")(date);
          }
        })
      );

    xAxis.select("path").remove();
    xAxis
      .selectAll(".tick")
      .selectAll("line")
      .remove();

    let xAxisTitle = chartSVG
      .append("text")
      .text("Transaction Date")
      .attr("font-size", "12px")
      .attr("fill", "black");

    xAxisTitle.attr(
      "transform",
      `translate(${width / 2 -
        xAxisTitle.node().getBBox().width / 2}, ${height})`
    );

    let legend = chartSVG.append("g");
    legend
      .append("rect")
      .attr("fill", "#41b29e")
      .attr("height", "20px")
      .attr("width", "40px");
    legend
      .append("text")
      .attr("x", "50px")
      .attr("y", "20px")
      .attr("fill", "black")
      .attr("font-size", "18px")
      .text("Balance");

    legend.attr("transform", `translate(10, 0)`);

    // add class to origin tick
    chartSVG.selectAll(".tick").classed("origin", function(d, i) {
      return d === 0;
    });

    chartSVG
      .on("mouseover", function(d, i) {
        let sortedTransactionData = agg_trans
          .slice()
          .map(transaction => {
            return {
              date: moment(transaction.date).toDate(),
              account_balance: transaction.account_balance
            };
          })
          .sort((a, b) => a.date - b.date);
        let cursorX = d3.mouse(d3.select(this).node())[0];
        let refDate = xScale.invert(cursorX);
        let bisector = d3.bisector(d => d.date);
        let bisect = bisector.right(sortedTransactionData, refDate);
        if (bisect >= sortedTransactionData.length) {
          bisect = sortedTransactionData.length - 1;
        }

        let bisectDate = sortedTransactionData[bisect].date;
        let bisectBalance = sortedTransactionData[bisect].account_balance;

        let tooltipXPosition = xScale(bisectDate);
        let tooltipYPosition = yScale(bisectBalance);
        const xAdjust = width - tooltipXPosition < 140 ? 140 : 0;
        const yAdjust = height - tooltipYPosition < 50 ? 50 : 0;

        tooltip
          .attr(
            "transform",
            `translate(${tooltipXPosition - xAdjust}, ${tooltipYPosition -
              yAdjust})`
          )
          .attr("opacity", "90%");

        let tooltipDate = tooltip.select("#tooltip-date");
        tooltipDate.text(moment(bisectDate).format("YYYY-MM-DD"));

        let tooltipBalance = tooltip.select("#tooltip-balance");
        tooltipBalance.text(`Balance: ${bisectBalance.toFixed(2)}`);

        let tooltipAdjustedWidth =
          5 + tooltipBalance.node().getBBox().width + 5;
        tooltip.select("rect").attr("width", tooltipAdjustedWidth);
      })
      .on("mouseout", function(d, i) {
        tooltip.attr("opacity", "0%");
      });

    var tooltip = chartSVG
      .append("g")
      .attr("class", "tooltip")
      .attr("opacity", "0%");

    tooltip.append("rect").attr("fill", "black");

    tooltip
      .append("text")
      .attr("id", "tooltip-date")
      .attr("fill", "white");
    tooltip
      .append("text")
      .attr("id", "tooltip-balance")
      .attr("fill", "white");
  };

  return (
    <div className="bal-graph" id={`line-graph-${props.application.id}`}>
      <svg id={`svg-bal-graph-${props.application.id}`}></svg>
    </div>
  );
}
