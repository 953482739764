import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchUnderwritingDecisions,
  expandDecision,
} from "../actions/underwriting_actions";
import UnprocessedDecision from "./UnprocessedDecision";
import Navbar from "./Navbar_container";

import Loader from "react-loader";

class UnderwritingDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { decisionsLoaded: false, preloadQueue: null };
  }

  loaderOptions = {
    lines: 10,
    length: 10,
    width: 5,
    radius: 5,
    scale: 0.75,
    color: "#41b29e",
    zIndex: 2e9,
    top: "10px",
    left: "50%",
    position: "relative",
  };

  componentDidMount() {
    const callback = (ids) =>
      this.setState({ decisionsLoaded: true, preloadQueue: ids });
    this.props.fetchUnderwritingDecisions(callback);
  }

  backgroundLoad = (currentlyLoadingId) => {
    let tmpPreloadQueue = this.state.preloadQueue.filter(
      (x) => x != currentlyLoadingId
    );

    if (tmpPreloadQueue.length > 0) {
      const idToLoad = tmpPreloadQueue.shift();
      const onError = (id) => {
        alert(`No loan found with id ${id}`);
      };
      this.props.expandDecision(idToLoad, "decision_id", onError);
    }
    this.setState({ preloadQueue: tmpPreloadQueue });
  };

  render() {
    return (
      <main>
        <Navbar />
        <body class="blackbg internal">
          <div class="dashboard">
            <h1>Unprocessed Loans</h1>

            <div class="unprocessed">
              <div class="loan">
                <div className="summary">
                  <div class="id cell">
                    <b>Id</b>
                  </div>
                  <div class="name cell">
                    <b>Name</b>
                  </div>
                  <div class="amount cell">
                    <b>Amount</b>
                  </div>
                  <div class="applied cell">
                    <b>Applied</b>
                  </div>
                  <div class="comment cell">
                    <b>Comment</b>
                  </div>
                  <div class="actions cell">
                    <b>Actions</b>
                  </div>
                  <div class="experiment cell">
                    <b>Experiment</b>
                  </div>
                </div>
              </div>
              <Loader
                loaded={this.state.decisionsLoaded}
                options={this.loaderOptions}
              >
                {this.props.normal_decisions.map((decision) => (
                  <UnprocessedDecision
                    key={decision.loan_id}
                    decision={decision}
                    backgroundLoad={this.backgroundLoad}
                  />
                ))}
              </Loader>
            </div>
            <br />
            <hr />

            <h1>Unprocessed Loans - Manuals Received</h1>
            <div class="unprocessed">
              <div class="loan">
                <div className="summary">
                  <div class="id cell">
                    <b>Id</b>
                  </div>
                  <div class="name cell">
                    <b>Name</b>
                  </div>
                  <div class="amount cell">
                    <b>Amount</b>
                  </div>
                  <div class="applied cell">
                    <b>Applied</b>
                  </div>
                  <div class="comment cell">
                    <b>Comment</b>
                  </div>
                </div>
              </div>

              <Loader
                loaded={this.state.decisionsLoaded}
                options={this.loaderOptions}
              >
                {this.props.manual_decisions.map((decision, i) => (
                  <UnprocessedDecision
                    key={decision.loan_id}
                    decision={decision}
                    index={i}
                  />
                ))}
              </Loader>
            </div>
            <br />
            <hr />
            <h1>Clarity Fraud Alert</h1>
            <div class="unprocessed">
              <div class="loan">
                <div className="summary">
                  <div class="id cell">
                    <b>Id</b>
                  </div>
                  <div class="name cell">
                    <b>Name</b>
                  </div>
                  <div class="amount cell">
                    <b>Amount</b>
                  </div>
                  <div class="applied cell">
                    <b>Applied</b>
                  </div>
                  <div class="comment cell">
                    <b>Comment</b>
                  </div>
                </div>
              </div>

              <Loader
                loaded={this.state.decisionsLoaded}
                options={this.loaderOptions}
              >
                {this.props.clarity_loans.map((decision) => (
                  <UnprocessedDecision
                    key={decision.loan_id}
                    decision={decision}
                  />
                ))}
              </Loader>
            </div>
            <br />
            <hr />
          </div>
        </body>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  normal_decisions: Object.values(
    state.entities.underwriting_decisions.normal_decisions
  ),
  manual_decisions: Object.values(
    state.entities.underwriting_decisions.manual_decisions
  ),
  clarity_loans: Object.values(
    state.entities.underwriting_decisions.clarity_loans
  ),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUnderwritingDecisions: (callback) =>
    dispatch(fetchUnderwritingDecisions(callback)),
  expandDecision: (id, idType, onError) =>
    dispatch(expandDecision(id, idType, onError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnderwritingDashboard);
