import React, { Component } from "react";
import Navbar from "./Navbar_container";
import { connect } from "react-redux";
import { getMarketingAnalytics } from "../actions/analytics_actions";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "../styles/css/analytics.css";

class MarketingAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      reg_regime: null,
      startDate: moment(),
      endDate: moment().add(1, "day")
    };
  }

  componentDidMount() {
    this.fetchAnalytics();
  }

  fetchAnalytics = () => {
    if (!this.state.loading) this.setState({ loading: true });
    this.props.getMarketingAnalytics(
      this.state.startDate.format("YYYY-MM-DD"),
      this.state.endDate.format("YYYY-MM-DD"),
      () => this.setState({ loading: false })
    );
  };

  handleSelectChange = e => {
    const oldState = this.state.reg_regime
      ? this.state.reg_regime.slice(0)
      : [];
    if (oldState.includes(e.target.value)) {
      for (var i = 0; i < oldState.length; i++) {
        if (oldState[i] === e.target.value) {
          oldState.splice(i, 1);
        }
      }
    } else {
      oldState.push(e.target.value);
    }
    this.setState({ reg_regime: oldState });
  };

  validateDay = day => {
    return day > moment().add(1, "days");
  };

  render() {
    let texas, missouri, ohio, utah, illinois, affiliate;

    ({
      texas,
      missouri,
      ohio,
      utah,
      illinois,
      affiliate
    } = this.props.analytics);
    return (
      <main>
        <Navbar />
        <div className="analytics-show">
          <div id="analytics-params">
            <ul>
              <li>
                <DateRangePicker
                  startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                  startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                  endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                  endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                  onDatesChange={({ startDate, endDate }) =>
                    this.setState({ startDate, endDate })
                  } // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput =>
                    this.setState({ focusedInput })
                  } // PropTypes.func.isRequired,
                  isOutsideRange={this.validateDay}
                />
              </li>
              <li>
                <i style={{ fontSize: "14px" }}>
                  *Date selection is inclusive*
                </i>
              </li>
              <li>
                <button onClick={this.fetchAnalytics}>Calculate</button>
              </li>
            </ul>
          </div>
          {this.props.analytics && !this.state.loading
            ? [
                <div id="state-info" key={1}>
                  <h3>Texas</h3>
                  <i>First Application</i>
                  <ul>
                    <li>Applied: {texas.first.applied}</li>
                    <li>Completed BV: {texas.first.completed}</li>
                    <li>Approved: {texas.first.approved}</li>
                    <li>Accepted: {texas.first.accepted}</li>
                  </ul>
                  <i>Repeat Application</i>
                  <ul>
                    <li>Applied: {texas.repeat.applied}</li>
                    <li>Completed BV: {texas.repeat.completed}</li>
                    <li>Approved: {texas.repeat.approved}</li>
                    <li>Accepted: {texas.repeat.accepted}</li>
                  </ul>
                </div>,
                <div id="state-info" key={2}>
                  <h3>Missouri</h3>
                  <i>First Application</i>
                  <ul>
                    <li>Applied: {missouri.first.applied}</li>
                    <li>Completed BV: {missouri.first.completed}</li>
                    <li>Approved: {missouri.first.approved}</li>
                    <li>Accepted: {missouri.first.accepted}</li>
                  </ul>
                  <i>Repeat Application</i>
                  <ul>
                    <li>Applied: {missouri.repeat.applied}</li>
                    <li>Completed BV: {missouri.repeat.completed}</li>
                    <li>Approved: {missouri.repeat.approved}</li>
                    <li>Accepted: {missouri.repeat.accepted}</li>
                  </ul>
                </div>,
                <div id="state-info" key={3}>
                  <h3>Illinois</h3>
                  <i>First Application</i>
                  <ul>
                    <li>Applied: {illinois.first.applied}</li>
                    <li>Completed BV: {illinois.first.completed}</li>
                    <li>Approved: {illinois.first.approved}</li>
                    <li>Accepted: {illinois.first.accepted}</li>
                  </ul>
                  <i>Repeat Application</i>
                  <ul>
                    <li>Applied: {illinois.repeat.applied}</li>
                    <li>Completed BV: {illinois.repeat.completed}</li>
                    <li>Approved: {illinois.repeat.approved}</li>
                    <li>Accepted: {illinois.repeat.accepted}</li>
                  </ul>
                </div>,
                <div id="state-info" key={4}>
                  <h3>Utah</h3>
                  <i>First Application</i>
                  <ul>
                    <li>Applied: {utah.first.applied}</li>
                    <li>Completed BV: {utah.first.completed}</li>
                    <li>Approved: {utah.first.approved}</li>
                    <li>Accepted: {utah.first.accepted}</li>
                  </ul>
                  <i>Repeat Application</i>
                  <ul>
                    <li>Applied: {utah.repeat.applied}</li>
                    <li>Completed BV: {utah.repeat.completed}</li>
                    <li>Approved: {utah.repeat.approved}</li>
                    <li>Accepted: {utah.repeat.accepted}</li>
                  </ul>
                </div>,
                <div id="state-info" key={5}>
                  <h3>Ohio</h3>
                  <i>First Application</i>
                  <ul>
                    <li>Applied: {ohio.first.applied}</li>
                    <li>Completed BV: {ohio.first.completed}</li>
                    <li>Approved: {ohio.first.approved}</li>
                    <li>Accepted: {ohio.first.accepted}</li>
                  </ul>
                  <i>Repeat Application</i>
                  <ul>
                    <li>Applied: {ohio.repeat.applied}</li>
                    <li>Completed BV: {ohio.repeat.completed}</li>
                    <li>Approved: {ohio.repeat.approved}</li>
                    <li>Accepted: {ohio.repeat.accepted}</li>
                  </ul>
                </div>,
                <div id="state-info" key={6}>
                  <h3>Affiliate</h3>
                  <i>First Application</i>
                  <ul>
                    <li>Applied: {affiliate.first.applied}</li>
                    <li>Completed BV: {affiliate.first.completed}</li>
                    <li>Approved: {affiliate.first.approved}</li>
                    <li>Accepted: {affiliate.first.accepted}</li>
                  </ul>
                  <i>Repeat Application</i>
                  <ul>
                    <li>Applied: {affiliate.repeat.applied}</li>
                    <li>Completed BV: {affiliate.repeat.completed}</li>
                    <li>Approved: {affiliate.repeat.approved}</li>
                    <li>Accepted: {affiliate.repeat.accepted}</li>
                  </ul>
                </div>
              ]
            : "...Loading"}
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  analytics: state.analytics
});

const mapDispatchToProps = dispatch => ({
  getMarketingAnalytics: (startDate, endDate, cb) =>
    dispatch(getMarketingAnalytics(startDate, endDate, cb))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketingAnalytics);
