import React from "react";

export default function AutocompleteSearch(props) {
  const tagSelectOptions =
    props.selectOptions.length > 0
      ? props.selectOptions
          .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
          .map((tag, idx) => <option key={idx} value={tag} />)
      : null;

  return (
    <div className="tag-search-container">
      <form
        className={`autocomplete-form ${props.alignment}`}
        onSubmit={ev => {
          ev.preventDefault();
          if (props.onSubmit) {
            props.onSubmit(ev);
          }
        }}
      >
        <input
          id={props.search_id}
          onChange={ev => (props.onInput ? props.onInput(ev) : null)}
          value={props.inputValue ? props.inputValue : ""}
          className="tag-search-input"
          list={props.name}
        />
        <datalist id={props.name}>{tagSelectOptions}</datalist>
        <input type="submit" hidden />
      </form>
    </div>
  );
}
