import { domain } from "../helpers/route_helper";

export const calcEarlyRepay = (loan_id, date) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/users/early_repay_calculate`,
    data: { loan_id, date }
  });
};

export const changePayment = payment => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/change_payment`,
    data: { payment }
  });
};

export const linkTask = (user_id, ticket_id, email) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/users/link_ticket`,
    data: { user_id, ticket_id, email }
  });
};

export const completeTask = (id, reply, mode, review) => {
  console.log("complete task called");
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/complete_help`,
    data: { id, reply, mode, review }
  });
};

export const completeIdentification = (id, decision) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/complete_identification`,
    data: { id: id, decision: decision }
  });
};

export const fetchTasks = (
  starred,
  tags,
  pull_assigned,
  sort,
  auto_refresh = false,
  tickets_in_dashboard = [],
  completed_tasks = []
) => {
  console.log(
    `fetch tasks called (autorefresh: ${auto_refresh ? "on" : "off"})`
  );
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/users/need_help`,
    data: {
      starred,
      tags,
      pull_assigned,
      sort,
      auto_refresh,
      tickets_in_dashboard,
      completed_tasks
    }
  });
};

export const fetchDocuments = (user_id, loan_id = null) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/users/fetch_documents`,
    data: { user_id: user_id, loan_id: loan_id }
  });
};

export const fetchIdentifications = () => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/users/fetch_identifications`,
    data: {}
  });
};

export const createEarlyRepay = (loan_id, date) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/users/create_early_repay`,
    data: { loan_id, date }
  });
};

export const splitPayment = payment => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/split_payment`,
    data: { payment }
  });
};

export const authorizePayment = payment => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/authorize_payment`,
    data: { payment }
  });
};

export const turnOnOffAch = loan_id => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/turn_on_off_ach`,
    data: { loan_id }
  });
};

export const dashboardEditInfo = (parameter, text, id, force) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/edit_info`,
    data: { parameter, text, id, force }
  });
};

export const resetPassword = id => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/reset_password`,
    data: { id }
  });
};

export const reOpenApproval = (ticketID, id) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/re_open_approval`,
    data: { ticketID, id }
  });
};

export const addTagToTicket = (ticketID, tagName, response) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/tags/add_tag_to_ticket`,
    data: { ticketID, tagName, response }
  });
};

export const deleteTagFromTicket = (ticketID, tagID, response) => {
  return window.$.ajax({
    method: "DELETE",
    url: `${domain}/internal/api/tags/delete_tag_from_ticket`,
    data: { ticketID, tagID, response }
  });
};

export const deleteTagFromDatabase = (ticketIDs, tagID, response) => {
  return window.$.ajax({
    method: "DELETE",
    url: `${domain}/internal/api/tags/delete_tag_from_database`,
    data: { ticketIDs, tagID, response }
  });
};

export const assignEmployeeToTicket = (ticketID, assignToEmployeeID) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/assign_employee_to_ticket`,
    data: { ticketID, assignToEmployeeID }
  });
};

export const getTaskCount = () => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/users/need_help_count`
  });
};

export const unassignAll = () => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/users/unassign_all_tasks`
  });
};

export const resendApprovalText = id => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/users/resend_approval_text`,
    data: { loan_id: id }
  });
};
