import React from "react";

const UnderwritingLoanDataRelationship = ({ relationship }) => {
  return (
    <div class="subcontainer">
      {relationship ? (
        <div>
          <h2>
            <b>Fig History / Fraud Detection</b>
          </h2>
          <p class="customer_note">
            Customer Notes: {relationship.user_comment}
          </p>{" "}
          <p>
            <u>{relationship.user_name}'s borrowing history with us</u>
          </p>
          {relationship.historical_loans.length > 0 ? (
            relationship.historical_loans.map(loan => (
              <div key={loan.loan_id}>
                <p class={loan.loan_warning ? "loan_warning" : ""}>
                  {loan.recovery_loan ? "RECOVERY " : ""} Loan ID:{" "}
                  {loan.loan_id} Amount: {loan.cash_advanced} Accepted:{" "}
                  {loan.accepted} Current Balance: ${loan.current_balance}{" "}
                  Profit: {loan.profit}
                  <br />
                  Payments Remaining: {loan.payments_remaining} Next Payment
                  Due: {loan.next_due} Next Payment Processing?:{" "}
                  {loan.processing} Number of Bounces: {loan.number_bounces}
                </p>
              </div>
            ))
          ) : (
            <p>No history detected</p>
          )}
          <p>
            <u>{relationship.user_name}'s applications since last funded</u>
          </p>
          {relationship.apps_since_last_funded.length > 0 ? (
            relationship.apps_since_last_funded.map(loan => (
              <div key={loan.loan_id}>
                <p>
                  {loan.approved} {loan.denied} {loan.applied} Loan ID:{" "}
                  {loan.loan_id} Amount: {loan.cash_advanced} Comment:{" "}
                  {loan.comment} Deny Reason: {loan.deny_reasons}
                </p>
              </div>
            ))
          ) : (
            <p>No history detected</p>
          )}
          <p>
            <u>
              Related users with at least 1 funded loan that either have 1. bank
              account, 2. address, 3. SSN or 4. email similarity with the
              applicant. SSN flags should be almost always denials. Bank Account
              matches require a reasonable explanation i.e. family member shared
              account etc... Email matches depend on the name similarity but our
              threshold is high enough that this too should largely be denied.
              Address is less reliable as it may be the same apartment complex.
            </u>
          </p>
          {relationship.users_with_loans.length > 0 ? (
            relationship.users_with_loans.map(loan => (
              <div>
                <p className={loan.paid_off ? "" : "loan_warning"}>
                  {loan.fraud_warning ? (
                    <div>
                      <b>WARNING: FRAUD OR OTHERWISE DEAD</b>
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  User Id: {loan.user_id} User Name: {loan.user_name} Loan ID:{" "}
                  {loan.loan_id} Amount: {loan.cash_advanced}; Accepted:{" "}
                  {loan.accepted}; Current Balance: {loan.current_balance}
                  <br />
                  Payments Remaining: {loan.payments_remaining}; Next Payment
                  Due: {loan.payment_next_due};
                  {loan.payment_processing ? (
                    <b>Payment in Processing!</b>
                  ) : (
                    "No payment processing;"
                  )}
                  Number of Bounces: {loan.number_bounces}
                  <br />
                  <b>
                    Matches on:{" "}
                    <span class="loan_warning">
                      {" "}
                      {loan.match_message}{" "}
                      {loan.address_match_string_street ? (
                        <span>
                          Address -{" "}
                          <a
                            href={
                              "https://google.com/search?q=" +
                              loan.address_match_string
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {loan.address_match_string_street}
                          </a>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </b>
                </p>
              </div>
            ))
          ) : (
            <p>None detected</p>
          )}
          <p>
            <u>
              Related users with no funded loans. See explanation above for
              level differences
            </u>
          </p>
          {relationship.users_without_loans.length > 0 ? (
            relationship.users_without_loans.map(user => (
              <div>
                <p>
                  User Id: {user.user_id} User Name: {user.user_name} Email:{" "}
                  {user.email} Address: {user.address}
                  <br />
                  <b>
                    Matches on:
                    <span class="loan_warning">
                      {" "}
                      {user.match_message}{" "}
                      {user.address_match_string_street ? (
                        <span>
                          Address -{" "}
                          <a
                            href={
                              "https://google.com/search?q=" +
                              user.address_match_string
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {user.address_match_string_street}
                          </a>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </b>
                </p>
              </div>
            ))
          ) : (
            <p>None detected</p>
          )}
        </div>
      ) : (
        ""
      )}
      <div class="clear"></div>
    </div>
  );
};

export default UnderwritingLoanDataRelationship;
