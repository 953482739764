import { merge, assign } from "lodash";
import {
  RECEIVE_TASK_COUNT,
  RECEIVE_TASK,
  RECEIVE_TASKS,
  RECEIVE_STAR,
  ASSIGN_TASK,
  CHANGE_TASK_PAYMENT,
  UPDATE_TASK,
  COMPLETE_TASK,
  AUTOREFRESH
} from "../actions/task_actions";

const tasksReducer = (
  oldState = {
    tasks: {},
    count: 0,
    starred: [],
    task_order: [],
    completed_tasks: []
  },
  action
) => {
  Object.freeze(oldState);
  let newState;
  let task_id;
  switch (action.type) {
    case RECEIVE_TASK_COUNT:
      const copy = merge({}, oldState);
      copy["count"] = action.count.count;
      return copy;
    case RECEIVE_TASK:
      var incomingTasks = action.tasks.tasks;
      let updatedTagList = null;

      var incoming_task_ids = Object.keys(action.tasks.tasks);
      let global_task_order = oldState.task_order.concat(
        incoming_task_ids.filter(item => oldState.task_order.indexOf(item) < 0)
      );

      var new_state_task_order = global_task_order.sort(function(a, b) {
        return global_task_order.indexOf(a) - global_task_order.indexOf(b);
      });

      var tasksCopy = oldState.tasks;
      tasksCopy[Object.keys(action.tasks.tasks)[0]] = Object.values(
        action.tasks.tasks
      )[0];
      var new_state_task_list = tasksCopy;

      newState = Object.assign({}, oldState, {
        tasks: new_state_task_list,
        employeeList: action.tasks.employeeList
          ? action.tasks.employeeList
          : oldState.employeeList,
        count: action.tasks.count,
        tagList: action.tasks.tagList
          ? action.tasks.tagList
          : updatedTagList
          ? updatedTagList
          : oldState.tagList,
        task_order: new_state_task_order
      });
      newState.tasks = new_state_task_list;

      return newState;
    case RECEIVE_TASKS:
      if (!action.tasks.tasks) {
        const copy = merge({}, oldState, action.tasks, action.tasks.tagList);
        if (action.id) {
          delete copy.tasks[action.id];
          copy["starred"] = copy["starred"].filter(id => id !== action.id);
        }
        copy.tasks = {};
        return copy;
      } else {
        var incomingTasks = action.tasks.tasks;
        let updatedTagList = null;

        var incoming_task_ids = Object.keys(action.tasks.tasks);
        let global_task_order = oldState.task_order.concat(
          incoming_task_ids.filter(
            item => oldState.task_order.indexOf(item) < 0
          )
        );

        incoming_task_ids.sort(function(a, b) {
          return global_task_order.indexOf(a) - global_task_order.indexOf(b);
        });

        newState = Object.assign({}, oldState, {
          tasks: incomingTasks,
          employeeList: action.tasks.employeeList
            ? action.tasks.employeeList
            : oldState.employeeList,
          count: action.tasks.count ? action.tasks.count : oldState.count,
          tagList: action.tasks.tagList
            ? action.tasks.tagList
            : updatedTagList
            ? updatedTagList
            : oldState.tagList,
          task_order: incoming_task_ids
        });
        newState.tasks = incomingTasks;

        return newState;
      }
    case COMPLETE_TASK:
      oldState.completed_tasks.push(action.id);

      newState = Object.assign({}, oldState, {
        employeeList: oldState.employeeList,
        count: oldState.count - 1,
        tagList: oldState.tagList,
        completed_tasks: oldState.completed_tasks
      });
      delete newState.tasks[action.id];
      return newState;
    case UPDATE_TASK:
      task_id = Object.keys(action.task.tasks)[0];
      newState = Object.assign({}, oldState, {
        employeeList: oldState.employeeList,
        count: action.task.count,
        tagList: oldState.tagList
      });
      newState.tasks[task_id] = action.task.tasks[task_id];
      return newState;
    case CHANGE_TASK_PAYMENT:
      task_id = Object.keys(action.task.tasks)[0];

      newState = Object.assign({}, oldState, {
        employeeList: oldState.employeeList,
        count: action.task.count,
        tagList: oldState.tagList
      });

      newState.tasks[task_id].guesses = action.task.tasks[task_id].guesses;
      newState.tasks[task_id].active_loan.paymentsDue =
        action.task.tasks[task_id].active_loan.paymentsDue;
      return newState;
    case ASSIGN_TASK:
      const updatedTaskList = oldState.tasks;
      if (action.assignTo === "Unassign") {
        updatedTaskList[action.ticketID].employee_id = null;
      } else if (action.assignTo !== action.employeeID) {
        delete updatedTaskList[action.ticketID];
      } else {
        updatedTaskList[action.ticketID].employee_id = action.employeeID;
      }
      return Object.assign({}, oldState, { tasks: updatedTaskList });
    case RECEIVE_STAR:
      const starCopy = [...oldState.starred];
      const alreadyFlagged = starCopy.indexOf(action.id);
      alreadyFlagged !== -1
        ? starCopy.splice(alreadyFlagged, 1)
        : starCopy.push(action.id);
      return assign({}, oldState, { starred: starCopy });
    case AUTOREFRESH: {
      console.log("entered autorefresh reducer");
      // oldState.completed_tasks.push(action.completed_task_id);

      var incomingTasks = action.new_task.tasks;
      let updatedTagList = null;

      var incoming_task_ids = Object.keys(action.new_task.tasks);
      let global_task_order = oldState.task_order.concat(
        incoming_task_ids.filter(item => oldState.task_order.indexOf(item) < 0)
      );

      var new_state_task_order = global_task_order.sort(function(a, b) {
        return global_task_order.indexOf(a) - global_task_order.indexOf(b);
      });
      // delete new_state_task_order[action.completed_task_id];

      var tasksCopy = oldState.tasks;
      for (const id in action.new_task.tasks) {
        tasksCopy[id] = action.new_task.tasks[id];
      }
      // delete tasksCopy[action.completed_task_id];
      var new_state_task_list = tasksCopy;

      newState = Object.assign({}, oldState, {
        tasks: new_state_task_list,
        employeeList: action.new_task.employeeList
          ? action.new_task.employeeList
          : oldState.employeeList,
        count: action.new_task.count,
        tagList: action.new_task.tagList
          ? action.new_task.tagList
          : updatedTagList
          ? updatedTagList
          : oldState.tagList,
        task_order: new_state_task_order
      });
      newState.tasks = new_state_task_list;

      return newState;
    }
    default:
      return oldState;
  }
};

export default tasksReducer;
