export default {
  "John Li": 1,
  "Jeff Zhou": 2,
  "Nick West": 3,
  "Josh Velson": 4,
  "Zara Mohidin": 5,
  "Tom Stilwell": 6,
  "Ernesto DelValle": 7,
  Voldemort: 8,
  "Daniela Bucay": 9,
  "Albert Lin": 10,
  "Samer Bendary": 11,
  "Adriana Muncan": 12,
  "Sophia Yang": 45,
  "Stephanie Wasser": 78,
  "Jeremy Jaico": 111,
  "Jaice de Celis": 112,
  "Frankie Smith": 144,
  "Samantha Rock": 177,
  "Nick Chisholm": 210,
  "Colleen Welsh": 243,
  "Hayley Moir": 244,
  Unassigned: 0,
};
