import React, { Component } from "react";
import Navbar from "./Navbar_container";
import { connect } from "react-redux";
import { getAnalytics } from "../actions/analytics_actions";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "../styles/css/analytics.css";

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reg_regime: null,
      startDate: moment(),
      endDate: moment().add(1, "day")
    };
  }

  componentDidMount() {
    this.fetchAnalytics();
  }

  fetchAnalytics = () => {
    if (!this.state.loading) this.setState({ loading: true });
    this.props.getAnalytics(
      this.state.reg_regime,
      this.state.startDate.format("YYYY-MM-DD"),
      this.state.endDate.format("YYYY-MM-DD"),
      () => this.setState({ loading: false })
    );
  };

  handleSelectChange = e => {
    const oldState = this.state.reg_regime
      ? this.state.reg_regime.slice(0)
      : [];
    if (oldState.includes(e.target.value)) {
      for (var i = 0; i < oldState.length; i++) {
        if (oldState[i] === e.target.value) {
          oldState.splice(i, 1);
        }
      }
    } else {
      oldState.push(e.target.value);
    }
    this.setState({ reg_regime: oldState });
  };

  validateDay = day => {
    return day > moment().add(2, "days");
  };

  render() {
    return (
      <main>
        <Navbar />
        <div className="analytics-show">
          <div id="analytics-params">
            <ul>
              <li>
                <DateRangePicker
                  startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                  startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                  endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                  endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                  onDatesChange={({ startDate, endDate }) =>
                    this.setState({ startDate, endDate })
                  } // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput =>
                    this.setState({ focusedInput })
                  } // PropTypes.func.isRequired,
                  isOutsideRange={this.validateDay}
                />
              </li>
              <li>
                <select
                  value={this.state.reg_regime}
                  onChange={this.handleSelectChange}
                  multiple={true}
                  style={{ height: "100px", width: "100px" }}
                >
                  <option
                    value=""
                    onClick={() => this.setState({ reg_regime: [] })}
                  >
                    All
                  </option>
                  {["CaDdt","FlDpt","IlInt", "MoInt", "NmSla", "OhFla", "TxCso", "TxReg", "UtCcc", "mobile_app"].map(
                    val => <option value={val}>{val}</option>
                  )}
                </select>
              </li>
              <li>
                <button onClick={this.fetchAnalytics}>Calculate</button>
              </li>
            </ul>
          </div>
          {this.props.analytics && !this.state.loading
            ? [
                <div id="state-info" key={1}>
                  <h3>First Loans</h3>

                  <ul>
                    <li>
                      Applied Loans: {this.props.analytics.first_applied_num}
                    </li>
                    <li>
                      Completed BV Loans:{" "}
                      {this.props.analytics.first_completed_bv_num}
                    </li>
                    <li>
                      Approved Loans: {this.props.analytics.first_approved_num}
                    </li>
                    <li>
                      Secondary Approved Loans: {this.props.analytics.first_preapproved_num}
                    </li>
                    <li>
                      Accepted Loans: {this.props.analytics.first_accepted_num}
                    </li>
                    <li>
                      Funded Loans: {this.props.analytics.first_funded_num}
                    </li>
                  </ul>
                  <h3>Second Loans</h3>
                  <ul>
                    <li>
                      Applied Loans: {this.props.analytics.second_applied_num}
                    </li>
                    <li>
                      Completed BV Loans:{" "}
                      {this.props.analytics.second_completed_bv_num}
                    </li>
                    <li>
                      Approved Loans: {this.props.analytics.second_approved_num}
                    </li>
                    <li>
                      Accepted Loans: {this.props.analytics.second_accepted_num}
                    </li>
                    <li>
                      Funded Loans: {this.props.analytics.second_funded_num}
                    </li>
                  </ul>
                  <h3>Third+ Loans</h3>
                  <ul>
                    <li>
                      Applied Loans: {this.props.analytics.repeat_applied_num}
                    </li>
                    <li>
                      Completed BV Loans:{" "}
                      {this.props.analytics.repeat_completed_bv_num}
                    </li>
                    <li>
                      Approved Loans: {this.props.analytics.repeat_approved_num}
                    </li>
                    <li>
                      Accepted Loans: {this.props.analytics.repeat_accepted_num}
                    </li>
                    <li>
                      Funded Loans: {this.props.analytics.repeat_funded_num}
                    </li>
                  </ul>
                  <li>Total Funded: ${this.props.analytics.total_funded}</li>
                  <li>
                    Average Loan Size: ${this.props.analytics.average_funded}
                  </li>
                </div>,
                <div id="state-info" key={1}>
                  <h3>Today</h3>
                  <ul>
                    <li>To Fund: {this.props.analytics.total_to_fund}</li>
                    <li>
                      To Fund Amount: ${
                        this.props.analytics.total_to_fund_amount
                      }
                    </li>
                    <li>
                      Awaiting Verification To Fund: ${
                        this.props.analytics.awaiting_to_fund_amount
                      }
                    </li>
                    <li>Tickets Closed: {this.props.analytics.tickets}</li>
                  </ul>
                </div>,
                <div id="state-info" key={2}>
                  <h3>CreditBuilders</h3>
                  <ul>
                    <li>Applied: {this.props.analytics.applied_cbs}</li>
                    <li>Accepted: {this.props.analytics.accepted_cbs}</li>
                  </ul>
                </div>,
                <div id="state-info" key={5}>
                  <h3>Prequal</h3>
                  <ul>
                    <li>Applied: {this.props.analytics.prequal_applied}</li>
                    <li>Clarity Calls: {this.props.analytics.prequal_clarity_calls}</li>
                    <li>Qualified: {this.props.analytics.prequal_qualified}</li>
                    <li>Complete BV: {this.props.analytics.prequal_bv}</li>
                    <li>Approved: {this.props.analytics.prequal_approved}</li>
                    <li>Clickthroughs: {this.props.analytics.prequal_clickthroughs}</li>
                    <li>Accepted: {this.props.analytics.prequal_accepted}</li>
                    <li>Funded: {this.props.analytics.prequal_funded}</li>
                    <li>CPF: {this.props.analytics.prequal_cpf}</li>
                    <li>CPF Ratio: {this.props.analytics.prequal_cpf_ratio}</li>
                  </ul>
                </div>
              ]
            : "...Loading"}
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  analytics: state.analytics
});

const mapDispatchToProps = dispatch => ({
  getAnalytics: (reg_regime, startDate, endDate, cb) =>
    dispatch(getAnalytics(reg_regime, startDate, endDate, cb))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Analytics);
