import { merge } from "lodash";
import { RECEIVE_ANALYTICS } from "../actions/analytics_actions";

const defaultProps = {};

const analyticsReducer = (oldState = defaultProps, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_ANALYTICS:
      return merge({}, oldState, action.analytics);
    default:
      return oldState;
  }
};

export default analyticsReducer;
