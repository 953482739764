import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as APIUtil from "../../util/tasks_api_util";

class ViewDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  pullDocuments = () => {
    if (this.props.handleProcessing) this.props.handleProcessing();

    APIUtil.fetchDocuments(this.props.user_id, this.props.loan_id).then((documents) => {
      const render = [];

      if (
        documents.documents &&
        documents.documents[this.props.user_id] &&
        documents.documents[this.props.user_id].images
      ) {
        render.push(
          Object.values(documents.documents[this.props.user_id].images).map(
            (document) => (
              <div
                className="db-document-container"
                style={{ display: "block" }}
                key={document.doc_id}
              >
                <p className="db-document-details">
                  {document?.doc_type?.toString().toUpperCase()} :{" "}
                  {document.doc_id}
                </p>
                <p className="db-document-details">
                  Created On:{" "}
                  {moment(document.created_at).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )}
                </p>
                <a
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  href={document.url}
                  download
                >
                  <button className="db-document-button">Download</button>
                </a>
                <div className="image_container">
                  {document.image_type ? (
                    <img
                      src={document.url}
                      alt="Cannot be rendered"
                      className="image"
                    />
                  ) : (
                    <embed src={document.url} alt="Cannot be rendered" />
                  )}
                </div>
              </div>
            )
          )
        );
      }

      const comp = (
        <div className="documents">
          {render.length === 0 ? "No Documents Uploaded" : render}
        </div>
      );

      this.props.handleShowModal(comp);
    }, null);
  };

  render() {
    return (
      <div className="view-documents">
        <button
          className="dashboard_button ui-button"
          onClick={() => this.pullDocuments()}
        >
          View Documents
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  errors: state.errors.session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDocuments);
