import React from "react";

const UnderwritingLoanDataAnalysis = ({ analysis }) => {
  const colors = ["orange", "goldenrod", "teal", "yellow_orange"];
  const results_verbage = ["Deny", "Undecided", "Approve", "Manual Process"];
  return (
    <div>
      <h2>Summary Analysis</h2>
      {analysis ? (
        <div id="app_summary">
          {analysis.loan_info ? (
            <>
              <h3>Loan Request</h3>
              <div className="row">
                <div className="left width_50">Borrower Name:</div>
                <div className="right width_50">{analysis.loan_info.user_name}</div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50">Borrower Email:</div>
                <div className="right width_50">{analysis.loan_info.email}</div>
                <div className="clear"></div>
              </div>
              <div
                className={
                  analysis.loan_info.utm_source === "refer" ? "row teal" : "row"
                }
              >
                <div className="left width_50">Utm:</div>
                <div className="right width_50">
                  {analysis.loan_info.utm_source}{" "}
                  {analysis.loan_info.utm_medium
                    ? "- " + analysis.loan_info.utm_medium
                    : ""}
                </div>
                <div className="clear"></div>
              </div>
              {analysis.loan_info.utm_source === "refer" ? (
                <div className="row teal">
                  <div className="left width_50">Referral ID:</div>
                  <div className="right width_50">
                    {analysis.loan_info.utm_content}
                  </div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="left width_50">Loan Amount:</div>
                <div className="right width_50">
                  {analysis.loan_info.cash_advanced}
                </div>
                <div className="left width_50">Loan Assessed:</div>
                <div className="right width_50">
                  {analysis.loan_info.assessed} EST
                </div>
                <div className="left width_50">Loan Applied:</div>
                <div className="right width_50">
                  {analysis.loan_info.applied} EST
                </div>
                <div className="clear"></div>
              </div>
            </>
          ) : null}
          {analysis.additional_info ? (
            <div>
              {analysis.additional_info.depository_account_is_prepaid ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">DEP ACCT IS PREPAID</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.all_income_irregular ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">ALL INCOME IRREGULAR</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.missing_primary_paychecks ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">RECENT PYCHKS MISSING</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.name_contains_numbers ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">NAME CONTAINS NUMBERS</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.recent_neg_bal_trend ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">NEGATIVE BAL. TREND</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.inactive_account ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">INACTIVE ACCOUNT</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.suspicious_transactions_last_month ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">SUSP. TRANS. LAST MONTH</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.gambling_last_month ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">GAMBLING LAST MONTH</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.lg_num_pmt_NSFs ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">PREV LOAN 4+ BOUNCES</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.UI_benefits_last_8_wks ||
              (analysis.additional_info.UI_benefits_adv &&
                analysis.additional_info.UI_benefits_adv.detected) ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">UI Benefits detected</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.loan_in_last_10_days ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">LOAN IN LAST 10 DAYS</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.returned_checks_last_month ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">RTN'D CHECKS LAST MONTH</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.account_number_verified ? (
                <div className="row teal">
                  <div className="left width_50">Deposit Account:</div>
                  <div className="right width_50">
                    Verified ({analysis.additional_info.verified_account_number}
                    )
                  </div>
                  <div className="clear"></div>
                </div>
              ) : (
                <div
                  className={"row" + analysis.additional_info.bv_alternate_color}
                >
                  <div className="left width_50">Deposit Account:</div>
                  <div className="right width_50">
                    {analysis.additional_info.bv_alternate_msg}
                  </div>
                  <div className="clear"></div>
                </div>
              )}{" "}
              {analysis.additional_info.deposit_acct_has_less_than_25 ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">
                    {"DEPOSIT ACCT HAS < 25 Entries"}
                  </div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              {analysis.additional_info.previous_defaults ? (
                <div className="row orange">
                  <div className="left width_50">WARNING:</div>
                  <div className="right width_50">PREVIOUS DEFAULT</div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}{" "}
              <div className="row">
                <div className="left width_50">User ID:</div>
                <div className="right width_50">{analysis.loan_info.user_id}</div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50">Loan ID:</div>
                <div className="right width_50">{analysis.loan_info.loan_id}</div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50">User Zip:</div>
                <div className="right width_50">{analysis.loan_info.user_zip}</div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50">User State:</div>
                <div className="right width_50">
                  {analysis.loan_info.user_state}
                </div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50"># of good plaids</div>
                <div className="right width_50">
                  {analysis.loan_info.num_plaids}
                </div>
                <div className="clear"></div>
              </div>
            </div>
          ) : (
            ""
          )}
          {analysis.additional_info && analysis.analysis_hash[0] ? (
            <div id="credit_analysis">
              <h3>Credit Analysis</h3>
              <div
                className={"row " + colors[analysis.analysis_hash[0].decision + 1]}
              >
                <div className="left width_50">Decision:</div>
                <div className="right width_50">
                  {results_verbage[analysis.analysis_hash[0].decision + 1]}
                </div>
                <div className="clear"></div>
              </div>

              {analysis.analysis_hash[0].counter_offer &&
              analysis.analysis_hash[0].counter_offer.rec_offer ? (
                <div>
                  <div className="row">
                    <div className="left width_50">Counter Offer Proposal:</div>
                    <div className="right width_50">
                      {analysis.analysis_hash[0].counter_offer.rec_offer}
                    </div>
                    <div className="clear"></div>
                  </div>
                  <div className="row">
                    <div className="left width_50">Counter Offer Type:</div>
                    <div className="right width_50">
                      {analysis.analysis_hash[0].counter_offer.type}
                    </div>
                    <div className="clear"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="left width_50">Score:</div>
                <div className="right width_50">
                  {Math.round(analysis.analysis_hash[0].score * 100) / 100}
                </div>
                <div className="clear"></div>
              </div>
              {analysis.analysis_hash[0].auto_counteroffer ? (
                <div className="row">
                  <div className="left width_50">Original Score:</div>
                  <div className="right width_50">
                    {Math.round(analysis.analysis_hash[0].old_score * 100) /
                      100}
                  </div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}
              {analysis.analysis_hash[0].seasonal_score_result ? (
                <div className="row">
                  <div className="left width_50">Seasonal Score:</div>
                  <div className="right width_50">
                    {Math.round(
                      analysis.analysis_hash[0].seasonal_score_result * 100
                    ) / 100}
                  </div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="left width_50">Approval Threshold:</div>
                <div className="right width_50">
                  {analysis.analysis_hash[0].threshold}
                </div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50">Deny Threshold:</div>
                <div className="right width_50">
                  {analysis.analysis_hash[0].deny_threshold}
                </div>
                <div className="clear"></div>
              </div>
              {analysis.analysis_hash[0].truncate_quant_metrics ? (
                <div className="row">
                  <div className="left width_50">Truncated Quant Metrics?</div>
                  <div className="right width_50">
                    {analysis.analysis_hash[0].truncate_quant_metrics}
                  </div>
                  <div className="clear"></div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {analysis.underlying_factors ? (
            <div id="underlying_factors">
              <h3>Underlying Factors</h3>
              <div className="row">
                <div className="left width_50">Last 3 mths PDL payments</div>
                <div className="right width_50">
                  {analysis.underlying_factors.last_3_months_pdl_payments.toFixed(
                    2
                  )}
                </div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50">Last 3 mths PDL borrowing</div>
                <div className="right width_50">
                  {analysis.underlying_factors.last_3_months_pdl_borrowing.toFixed(
                    2
                  )}
                </div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50">Last 3 mths PDL payments (Adj)</div>
                <div className="right width_50">
                  {analysis.underlying_factors.last_3_months_pdl_payments_adj.toFixed(
                    2
                  )}
                </div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50">Last 3 mths PDL borrowing (Adj)</div>
                <div className="right width_50">
                  {analysis.underlying_factors.last_3_months_pdl_borrowing_adj.toFixed(
                    2
                  )}
                </div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50">Average weekly income</div>
                <div className="right width_50">
                  {analysis.underlying_factors.average_weekly_income.toFixed(2)}
                </div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50">Average weekly income (Adj)</div>
                <div className="right width_50">
                  {analysis.underlying_factors.average_weekly_income_adj.toFixed(
                    2
                  )}
                </div>
                <div className="clear"></div>
              </div>
              {"average_weekly_income_sub_ui_adj" in
              analysis.underlying_factors ? (
                <div className="row">
                  <div className="left width_50">
                    Average weekly income (UI Adj)
                  </div>
                  <div className="right width_50">
                    {analysis.underlying_factors.average_weekly_income_sub_ui_adj.toFixed(
                      2
                    )}
                  </div>
                  <div className="clear"></div>
                </div>
              ) : null}
              <div className="row">
                <div className="left width_50">Average weekly income (4 wks)</div>
                <div className="right width_50">
                  {analysis.underlying_factors.average_weekly_income_last_4.toFixed(
                    2
                  )}
                </div>
                <div className="clear"></div>
              </div>
              {"average_weekly_income_sub_ui_adj_last_4" in
              analysis.underlying_factors ? (
                <div className="row">
                  <div className="left width_50">
                    Average weekly income last 4 (UI Adj)
                  </div>
                  <div className="right width_50">
                    {analysis.underlying_factors.average_weekly_income_sub_ui_adj_last_4.toFixed(
                      2
                    )}
                  </div>
                  <div className="clear"></div>
                </div>
              ) : null}
              <div className="row">
                <div className="left width_50">Average weekly spend</div>
                <div className="right width_50">
                  {analysis.underlying_factors.average_weekly_spend.toFixed(2)}
                </div>
                <div className="clear"></div>
              </div>
              <div className="row">
                <div className="left width_50">Average weekly spend (4 wks)</div>
                <div className="right width_50">
                  {analysis.underlying_factors.average_weekly_spend_last_4.toFixed(
                    2
                  )}
                </div>
                <div className="clear"></div>
              </div>
              {analysis.analysis_hash
                ? analysis.analysis_hash.slice(2, 10).map(metric => (
                    <div className="row" key={metric.display_note}>
                      <div className="left width_75">{metric.display_note}</div>
                      <div className="right width_25">
                        {Number(metric.display_value)
                          ? (
                              Math.round(metric.display_value * 1000) / 1000
                            ).toFixed(2)
                          : metric.display_value}
                      </div>
                      <div className="clear"></div>
                    </div>
                  ))
                : null}
            </div>
          ) : null}
          {analysis.analysis_hash ? (
            <div id="other_insights">
              <h3>Qualitative Insights</h3>
              <div className="row">
                <div className="left width_100">
                  <h4>Insights:</h4>
                </div>
                {analysis.analysis_hash[1].qualitative_insights.map(metric => (
                  <div className="right width_100" key={metric}>
                    {metric}
                  </div>
                ))}
                <div className="clear"></div>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UnderwritingLoanDataAnalysis;
