import React from "react";
import {
  consumerInformation,
  consumerNameInformation,
  consumerAddressInformation,
  covidDetail
} from "../../../features/fcra/fcraSlice";
import { useSelector } from "react-redux";
import { copyToClipboard } from "../../../util/clipboard";
import { formatRawPhone } from "../../../helpers/phone_helper";
import { formatRawSSN } from "../../../helpers/ssn_helper";
import { formatYearFirstDate } from "../../../helpers/date_helper";

export default function ConsumerInfo() {
  const consumerInfo = useSelector(consumerInformation);
  const consumerNameInfo = useSelector(consumerNameInformation);
  const consumerAddressInfo = useSelector(consumerAddressInformation);
  const covidDate = useSelector(covidDetail);
  
  const formattedDob = consumerInfo && formatYearFirstDate(consumerInfo.dob)

  const handleCopy = (value) => {
    copyToClipboard(value);
  };

  return (
    <section>
      <h2 id="consumerInfoHeader">
        <b>Consumer Information</b>
      </h2>
      <ul className="fcra-info" style={{ padding: 0, margin: 0 }}>
        {consumerNameInfo && (
          <>
            <li data-label="First name:">
              <span className="copyable" onClick={() => handleCopy(consumerNameInfo.firstName)}>
                {consumerNameInfo.firstName}
              </span>
            </li>
            <li data-label="Last name:">
              <span className="copyable" onClick={() => handleCopy(consumerNameInfo.lastName)}>
                {consumerNameInfo.lastName}
              </span>
            </li>
            <li data-label="Middle name:">
              <span className="copyable" onClick={() => handleCopy(consumerNameInfo.middleName)}>
                {consumerNameInfo.middleName}
              </span>
            </li>
            <li data-label="Generation code:">
              <span className="copyable" onClick={() => handleCopy(consumerNameInfo.generationCode)}>
                {consumerNameInfo.generationCode}
              </span>
            </li>
          </>
        )}
        <li data-label="SSN:">
          <span className="copyable" onClick={() => handleCopy(consumerInfo.ssn)}>
            {formatRawSSN(consumerInfo.ssn)}
          </span>
        </li>
        <li data-label="DOB:">
          <span className="copyable" onClick={() => handleCopy(formattedDob)}>
            {formattedDob}
          </span>
        </li>
        <li data-label="Phone number:">
          <span className="copyable" onClick={() => handleCopy(consumerInfo.phoneNumber)}>
            {formatRawPhone(consumerInfo.phoneNumber)}
          </span>
        </li>
        <li data-label="Consumer info indicator:">
          <span className="copyable" onClick={() => handleCopy(consumerInfo.consumerInfoIndicator)}>
            {consumerInfo.consumerInfoIndicator}
          </span>
        </li>
        <li data-label="ECOA code:">
          <span className="copyable" onClick={() => handleCopy(consumerInfo.ecoaCode)}>
            {consumerInfo.ecoaCode}
          </span>
        </li>
        {consumerAddressInfo && (
          <>
            <li data-label="Street:">
              <span className="copyable" onClick={() => handleCopy(consumerAddressInfo.street)}>
                {consumerAddressInfo.street}
              </span>
            </li>
            <li data-label="City:">
              <span className="copyable" onClick={() => handleCopy(consumerAddressInfo.city)}>
                {consumerAddressInfo.city}
              </span>
            </li>
            <li data-label="State:">
              <span className="copyable" onClick={() => handleCopy(consumerAddressInfo.state)}>
                {consumerAddressInfo.state}
              </span>
            </li>
            <li data-label="Zip:">
              <span className="copyable" onClick={() => handleCopy(consumerAddressInfo.zipCode)}>
                {consumerAddressInfo.zipCode}
              </span>
            </li>
            <li data-label="Covid:">
              <span className="copyable" onClick={() => handleCopy(covidDate)}>
                {covidDate ? covidDate : "N/A"}
              </span>
            </li>
          </>
        )}
      </ul>
    </section>
  );
}