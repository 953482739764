import * as APIUtil from "../util/error_db_api_util";

export const RECIEVE_ERROR_WARNINGS = "RECIEVE_ERROR_WARNINGS";
export const UPDATE_ERROR_WARNINGS = "UPDATE_ERROR_WARNINGS";
export const RECIEVE_RESOLVED_WARNINGS = "RECIEVE_RESOLVED_WARNINGS";

export const receiveErrorWarnings = (errorWarnings) => ({
  type: RECIEVE_ERROR_WARNINGS,
  errorWarnings,
});

export const updateErrorWarnings = (error_ids) => ({
  type: UPDATE_ERROR_WARNINGS,
  error_ids,
});

export const receiveResolvedWarnings = (warnings) => ({
  type: RECIEVE_RESOLVED_WARNINGS,
  warnings,
});

export const getErrorWarnings = (callback) => (dispatch) => {
  APIUtil.getErrorWarnings().then(
    (payload) => dispatch(receiveErrorWarnings(payload.error_warnings)),
    (errors) => callback(errors)
  );
};

export const resolveError = (closed_by, error_ids, callback) => (dispatch) => {
  APIUtil.resolveError(closed_by, error_ids).then((payload) => {
    console.log("@resolveError Payload ->", payload);
    dispatch(updateErrorWarnings(payload.error_ids));
    if (callback) {
      callback(payload);
    }
  });
};

export const getResolvedWarnings = (closer_id, callback) => (dispatch) => {
  APIUtil.getClosedWarnings(closer_id).then((payload) => {
    dispatch(receiveResolvedWarnings(payload));
    callback(payload);
  });
};
