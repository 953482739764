import { domain } from "../helpers/route_helper";

export const getAnalytics = (reg_regime, start_date, end_date) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/analytics`,
    data: { reg_regime, start_date, end_date }
  });
};

export const getMarketingAnalytics = (start_date, end_date) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/marketing_analytics`,
    data: { start_date, end_date }
  });
};
