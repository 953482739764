import React, { useState, useEffect } from "react";
import UnderwritingLoanData from "./UnderwritingLoanData";
import Loader from "react-loader";
import Navbar from "./Navbar_container";
import { expandDecision, expandPlaid } from "../util/underwriting_api_util";

export default function LoanReview(props) {
  const [idType, setIdType] = useState("loan");
  const [lookupId, setLookupId] = useState();
  const [loanData, setLoanData] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadLoanData = id => {
    setLoading(true);
    expandDecision(id, "loan").then(
      x => {
        setLoanData(Object.values(x)[0]);
        setLoading(false);
      },
      error => {
        alert(error.responseJSON.error || "Error 500");
        setLoading(false);
      }
    );
  };

  const loadPlaidData = id => {
    setLoading(true);
    expandPlaid(id).then(
      x => {
        setLoanData(Object.values(x)[0]);
        setLoading(false);
      },
      error => {
        alert(error.responseJSON.error || "Error 500");
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    const idPosition = window.location.hash.search("id");
    var queryString = window.location.hash.substr(idPosition);
    var urlParams = new URLSearchParams(queryString);
    var id = urlParams.get("id");
    var idType = urlParams.get("id_type");
    if (id) {
      setLookupId(id);
      if (idType == "plaid") {
        setIdType(idType);
        loadPlaidData(id);
      } else {
        loadLoanData(id);
      }
    }
  }, []);

  return (
    <main>
      <Navbar />
      <body class="blackbg internal">
        <div class="dashboard">
          <h1>
            Enter{" "}
            <select value={idType} onChange={e => setIdType(e.target.value)}>
              <option value="loan">Loan</option>
              <option value="plaid">Plaid Handler</option>
            </select>{" "}
            ID
            <input
              value={lookupId}
              onChange={e => setLookupId(e.target.value)}
              placeholder={`${idType == "loan" ? "Loan" : "Plaid Handler"} ID`}
            />
            <button
              onClick={() => {
                window.location = `#/loan_review?id=${lookupId}&id_type=${idType}`;
                window.location.reload();
              }}
            >
              Search
            </button>
          </h1>
          <div class="unprocessed" id="loan_holder">
            {loanData ? (
              <UnderwritingLoanData
                decision={loanData}
                closeExpansion={null}
                idType={idType}
              />
            ) : (
              <Loader loaded={!loading}></Loader>
            )}
          </div>
        </div>
      </body>
    </main>
  );
}
