import { connect } from "react-redux";
import { selectUserLoans } from "../reducers/selectors";
import UserShow from "./UserShow";
import { search } from "../actions/search_actions";
import { changeLoading } from "../actions/loader_actions";
import { withRouter } from "react-router";
import { completeTask } from "../actions/task_actions";

const mapStateToProps = (state, ownProps) => {
  return {
    userInfo: state.entities.user,
    task:
      state.entities.user && state.entities.tasks.tasks
        ? Object.values(state.entities.tasks.tasks).filter(
            task => task.user_id === state.entities.user.id
          )[0]
        : null,
    loans: selectUserLoans(state.entities.loans, state.entities.user.loan_ids),
    errors: state.errors.edit,
    macros: state.entities.macros
  };
};

const mapDispatchToProps = dispatch => ({
  search: (parameter, text) => dispatch(search(parameter, text)),
  load: () => dispatch(changeLoading(true)),
  completeTask: (id, reply) => dispatch(completeTask(id, reply))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserShow)
);
