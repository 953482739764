import {
  RECIEVE_ERROR_WARNINGS,
  UPDATE_ERROR_WARNINGS,
  RECIEVE_RESOLVED_WARNINGS,
} from "../actions/error_db_actions";

const defaultProps = {};

const errorDbReducer = (oldState = defaultProps, action) => {
  Object.freeze(oldState);
  let newState = {};
  switch (action.type) {
    case RECIEVE_ERROR_WARNINGS:
      newState = Object.assign({}, oldState, action.errorWarnings);
      return newState;
    case UPDATE_ERROR_WARNINGS:
      newState = Object.assign({}, oldState);
      Object.keys(newState).map((key) => {
        if (action.error_ids.includes(newState[key].id.toString())) {
          delete newState[key];
        }
      });
      return newState;
    case RECIEVE_RESOLVED_WARNINGS:
      newState = Object.assign({}, oldState);
      newState["resolvedWarnings"] = action.warnings;
      return newState;
    default:
      return oldState;
  }
};

export default errorDbReducer;
