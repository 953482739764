import React from "react";

export default function Transaction(props) {
  const trans_line =
    props.grouping && props.transaction.grouping === "zzzsubcription"
      ? ""
      : props.transaction.trans_line;

  return (
    <tr class={trans_line}>
      <td class="date" field="date">
        {props.transaction.date}
      </td>
      <td field="name">{props.transaction.name}</td>
      <td field="location">
        {props.transaction.location ? (
          <div>
            {props.transaction.location.city
              ? props.transaction.location.city + ", "
              : ""}
            {props.transaction.location.state
              ? props.transaction.location.state
              : ""}
          </div>
        ) : (
          ""
        )}
      </td>
      <td field="type">{props.accountName}</td>
      <td field="type_controller" style={{ display: "none" }}>
        {props.transaction.type.toString()}
      </td>
      <td className="amount" field="amount">
        ${props.transaction.amount.toFixed(2)}
      </td>
      <td className="balance" field="balance">
        <div class="depository">
          {props.transaction.account_balance.toFixed(2)}
        </div>
      </td>
    </tr>
  );
}
