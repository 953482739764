import { domain } from "../helpers/route_helper";

export const fetchNumbers = count => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/text_messages`,
    data: { count }
  });
};

export const fetchTexts = number => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/text_messages/${number}`
  });
};

export const sendText = (number, body) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/text_messages/texter_outbound`,
    data: { body, number }
  });
};

export const search = id => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/text_messages/search`,
    data: { search: id }
  });
};

export const markUnread = (number, count) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/text_messages/unread`,
    data: { number, count }
  });
};

export const fetchUnaddressed = () => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/text_messages/unaddressed`
  });
};
