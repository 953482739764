import * as APIUtil from "../util/tasks_api_util";
// import { receiveSessionErrors } from "./error_actions";
import { receiveLoading } from "./loader_actions";
export const RECEIVE_TASK_COUNT = "RECEIVE_TASK_COUNT";
export const RECEIVE_TASKS = "RECEIVE_TASKS";
export const RECEIVE_TASK = "RECEIVE_TASK";
export const RECEIVE_STAR = "RECEIVE_STAR";
export const ASSIGN_TASK = "ASSIGN_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const CHANGE_TASK_PAYMENT = "CHANGE_TASK_PAYMENT";
export const COMPLETE_TASK = "COMPLETE_TASK";
export const RECEIVE_IDENTIFICATIONS = "RECEIVE_IDENTIFICATIONS";
export const COMPLETE_IDENTIFICATION = "COMPLETE_IDENTIFICATION";
export const AUTOREFRESH = "AUTOREFRESH";

export const receiveTaskCount = count => ({
  type: RECEIVE_TASK_COUNT,
  count
});

export const receiveTask = (tasks, id) => ({
  type: RECEIVE_TASK,
  tasks,
  id
});

export const receiveTasks = (tasks, id) => ({
  type: RECEIVE_TASKS,
  tasks,
  id
});

export const receiveIdentifications = identifications => ({
  type: RECEIVE_IDENTIFICATIONS,
  identifications
});

export const receiveStar = id => ({
  type: RECEIVE_STAR,
  id
});

export const assignTask = (ticketID, assignTo, employeeID) => ({
  type: ASSIGN_TASK,
  ticketID,
  assignTo,
  employeeID
});

export const updateTask = (task, id) => ({
  type: UPDATE_TASK,
  task,
  id
});

export const changeTaskPayment = (task, id) => ({
  type: CHANGE_TASK_PAYMENT,
  task,
  id
});

export const removeTask = (task, id) => ({
  type: COMPLETE_TASK,
  task,
  id
});

export const removeIdentification = id => ({
  type: COMPLETE_IDENTIFICATION,
  id
});

export const autorefresh = new_task => ({
  type: AUTOREFRESH,
  new_task
});

export const calcEarlyRepay = (loan_id, date) => dispatch =>
  APIUtil.calcEarlyRepay(loan_id, date).then(tasks =>
    dispatch(updateTask(tasks))
  );

export const createEarlyRepay = (loan_id, date) => dispatch =>
  APIUtil.createEarlyRepay(loan_id, date).then(tasks =>
    dispatch(updateTask(tasks))
  );

export const dashboardEditInfo = (parameter, text, id, force) => dispatch =>
  APIUtil.dashboardEditInfo(parameter, text, id, force).then(
    tasks => dispatch(updateTask(tasks)),
    error => {
      alert(error.responseJSON.errors[0]);
    }
  );

export const changePayment = payment => dispatch =>
  APIUtil.changePayment(payment).then(tasks => {
    dispatch(changeTaskPayment(tasks));
  });

export const splitPayment = payment => dispatch =>
  APIUtil.splitPayment(payment).then(tasks => dispatch(updateTask(tasks)));

export const authorizePayment = payment => dispatch =>
  APIUtil.authorizePayment(payment).then(tasks => dispatch(updateTask(tasks)));

export const turnOnOffAch = loanId => dispatch =>
  APIUtil.turnOnOffAch(loanId).then(tasks => dispatch(updateTask(tasks)));

export const linkTask = (userID, ticketID, email) => dispatch =>
  APIUtil.linkTask(userID, ticketID, email).then(task =>
    dispatch(updateTask(task))
  );

export const completeTask = (id, reply, mode, review) => dispatch =>
  APIUtil.completeTask(id, reply, mode, review).then(tasks =>
    dispatch(removeTask(tasks, id))
  );

export const completeIdentification = (id, decision) => dispatch => {
  APIUtil.completeIdentification(id, decision).then(identifications =>
    dispatch(removeIdentification(id))
  );
};

export const fetchTasks = (starred, tags, pull_assigned, sort) => dispatch =>
  APIUtil.fetchTasks(starred, tags, pull_assigned, sort).then(
    tasks => {
      console.log("fetch tasks response recieved (autorefresh: off)");
      dispatch(receiveTasks(tasks));
      dispatch(receiveLoading(false));
    },
    error => {
      console.log("error from fetchTasks");
      console.error(error);
    }
  );

export const fetchAutorefreshTask = (
  starred,
  tags,
  pull_assigned,
  sort,
  auto_refresh,
  tickets_in_dashboard,
  completed_tasks
) => dispatch =>
  APIUtil.fetchTasks(
    starred,
    tags,
    pull_assigned,
    sort,
    auto_refresh,
    tickets_in_dashboard,
    completed_tasks
  ).then(
    new_task => {
      console.log("fetch tasks response recieved (autorefresh: on)");
      console.log("disptach following task to autorefresh reducer");
      console.log(new_task);
      dispatch(autorefresh(new_task));
    },
    error => {
      console.log("error from fetchAutorefreshTask");
      console.error(error);
    }
  );

export const resetPassword = id => dispatch =>
  APIUtil.resetPassword(id).then(tasks => dispatch(updateTask(tasks)));

export const reOpenApproval = (ticketID, id) => dispatch =>
  APIUtil.reOpenApproval(ticketID, id).then(tasks =>
    dispatch(updateTask(tasks))
  );

export const addTagToTicket = (ticketID, tagName, response) => dispatch =>
  APIUtil.addTagToTicket(ticketID, tagName, response).then(tasks =>
    dispatch(updateTask(tasks))
  );

export const deleteTagFromTicket = (ticketID, tagID, response) => dispatch =>
  APIUtil.deleteTagFromTicket(ticketID, tagID, response).then(tasks =>
    dispatch(updateTask(tasks))
  );

export const deleteTagFromDatabase = (ticketIDs, tagID, response) => dispatch =>
  APIUtil.deleteTagFromDatabase(ticketIDs, tagID, response).then(tasks =>
    dispatch(receiveTasks(tasks))
  );

export const fetchIdentifications = () => dispatch =>
  APIUtil.fetchIdentifications().then(identifications => {
    dispatch(receiveIdentifications(identifications));
    dispatch(receiveLoading(false));
  }, null);

export const assignEmployeeToTicket = (
  ticketID,
  assignTo,
  employeeID
) => dispatch =>
  APIUtil.assignEmployeeToTicket(ticketID, assignTo).then(task =>
    dispatch(assignTask(ticketID, assignTo, employeeID))
  );

export const getTaskCount = count => dispatch =>
  APIUtil.getTaskCount().then(count => dispatch(receiveTaskCount(count)));

export const unassignAll = callback => dispatch =>
  APIUtil.unassignAll().then(tasks => {
    callback();
    dispatch(receiveTasks(tasks));
  });

export const starTask = id => dispatch => dispatch(receiveStar(id));
