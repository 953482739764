import { merge } from "lodash";

import {
  RECEIVE_TASK,
  RECEIVE_TASKS,
  CHANGE_TASK_PAYMENT,
  UPDATE_TASK,
  AUTOREFRESH
} from "../actions/task_actions";
import { RECEIVE_LOAN_PAYMENTS } from "../actions/loan_payment_actions";

const defaultProps = {};

const loanPaymentsReducer = (oldState = defaultProps, action) => {
  Object.freeze(oldState);
  let newState;
  switch (action.type) {
    case RECEIVE_LOAN_PAYMENTS:
      newState = Object.assign({}, oldState, action.payments);
      return newState;
    case RECEIVE_TASK:
      newState = Object.assign(action.tasks.payments, oldState);
      return newState;
    case RECEIVE_TASKS:
      newState = Object.assign({}, oldState, action.tasks.payments);
      return newState;
    case CHANGE_TASK_PAYMENT:
      newState = Object.assign({}, oldState, action.task.payments);
      return newState;

    case UPDATE_TASK:
      newState = Object.assign({}, oldState, action.task.payments);
      return newState;
    case AUTOREFRESH:
      if (action.new_task.payments) {
        newState = Object.assign(action.new_task.payments, oldState);
        return newState;
      } else {
        return oldState;
      }
    default:
      return oldState;
  }
};

export default loanPaymentsReducer;
