import React, { Component } from "react";
import { connect } from "react-redux";
import { expandDecision } from "../actions/underwriting_actions";
import UnderwritingLoanDataAnalysis from "./UnderwritingLoanDataAnalysis";
import UnderwritingLoanDataRelationship from "./UnderwritingLoanDataRelationship";
import BalanceGraph from "./BalanceGraph";
import Transaction from "./Transaction";
import { cloneDeep, orderBy } from "lodash";
import Loader from "react-loader";

class UnderwritingLoanData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: "Depository",
      transactionType: "green",
      searchPhrase: ""
    };
  }

  componentDidMount() {
    if (!this.props.decision.expanded) {
      const onError = id => {
        alert(`No loan found with id ${id}`);
      };
      this.props.expandDecision(this.props.decision.id, "decision_id", onError);
    }
  }

  // UNTESTED - Leaving this in here in case we want to implement string searching
  levenshteinDistance = function(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    var matrix = [];

    // increment along the first column of each row
    var i;
    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    var j;
    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            Math.min(
              matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1
            )
          ); // deletion
        }
      }
    }

    return matrix[b.length][a.length];
  };
  // End UNTESTED Code

  onAccountChange = account_id => {
    this.setState({ account: account_id });
  };

  onTransactionTypeChange = type => {
    this.setState({ transactionType: type });
  };

  onSearchPhrase = input => {
    this.setState({ searchPhrase: input });
  };

  calculateLoanBalance = (loan_data, account_id, type, search = "") => {
    let loan_data_copy = cloneDeep(loan_data);
    let account = loan_data_copy.accounts.filter(
      x => x.account_id === account_id
    )[0];
    let account_balance = account.balance;
    let filteredTransactions = loan_data_copy.plaid.transactions.filter(
      x => account_id === "Depository" || x.account_id === account_id
    );
    loan_data_copy.plaid.transactions = filteredTransactions
      .map((transaction, idx) => {
        if (!transaction["account_balance"]) {
          transaction["amount"] *= -1;
          if (idx !== 0) {
            account_balance -= filteredTransactions[idx - 1]["amount"];
          }
          transaction["account_balance"] = account_balance;
        }

        return transaction;
      })
      .filter(x => {
        var typeFilter = false;
        switch (type) {
          case "all":
            typeFilter = true;
            break;
          case "LP Grouped":
          case "LP":
            typeFilter = ["funding_warning", "payment_warning"].includes(
              x.trans_line
            );
            break;
          case "debits":
            typeFilter = x.trans_line.includes("red");
            break;
          default:
            typeFilter = x.trans_line.includes(type);
            break;
        }
        return (
          typeFilter &&
          (x.name.toLowerCase().includes(search) ||
            x.amount.toString().includes(search))
        );
      });

    if (type === "LP Grouped") {
      loan_data_copy.plaid.transactions = orderBy(
        loan_data_copy.plaid.transactions,
        ["grouping", "date"],
        ["asc", "desc"]
      );
    }

    return loan_data_copy;
  };

  loaderOptions = {
    lines: 10,
    length: 10,
    width: 5,
    radius: 5,
    scale: 0.75,
    color: "#41b29e",
    zIndex: 2e9,
    top: "10px",
    left: "50%",
    position: "relative",
    marginTop: "20px"
  };

  groupTransactionData = formattedTransactionData => {
    var i = 0,
      val,
      index,
      values = [],
      result = [];

    for (; i < formattedTransactionData.length; i++) {
      val = formattedTransactionData[i]["grouping"];
      index = values.indexOf(val);
      if (index > -1) result[index].push(formattedTransactionData[i]);
      else {
        values.push(val);
        result.push([formattedTransactionData[i]]);
      }
    }
    return result;
  };

  render() {
    var formattedTransactionData;
    var balanceGraphTransactionData;
    var groupedFormattedTransactionData;

    if (this.props.decision.accounts) {
      formattedTransactionData = this.calculateLoanBalance(
        this.props.decision,
        this.state.account,
        this.state.transactionType,
        this.state.searchPhrase
      );
      balanceGraphTransactionData = this.calculateLoanBalance(
        this.props.decision,
        this.state.account,
        "all"
      );
      if (this.state.transactionType === "LP Grouped") {
        groupedFormattedTransactionData = this.groupTransactionData(
          formattedTransactionData.plaid.transactions
        );
      }
    }
    return (
      <Loader
        loaded={this.props.decision.expanded}
        options={this.loaderOptions}
      >
        <div>
          {this.props.closeExpansion ? (
            <div
              class="close_data"
              id="close"
              onClick={() => {
                this.props.closeExpansion();
              }}
            >
              Close
            </div>
          ) : null}

          <div class="analysis">
            <UnderwritingLoanDataAnalysis
              analysis={this.props.decision.analysis}
            />
          </div>
          <div class="details">
            {this.props.decision.relationship ? (
              <UnderwritingLoanDataRelationship
                relationship={this.props.decision.relationship}
              />
            ) : null}
            <div class="subcontainer balance-graph-container">
              <b>Account Balance</b>
              {this.props.decision.plaid && balanceGraphTransactionData ? (
                <BalanceGraph
                  plaid={balanceGraphTransactionData.plaid}
                  application={{
                    id: this.props.decision.id
                  }}
                />
              ) : null}
            </div>
            <br />
            <div class="subcontainer plaid">
              <div class="control">
                <div class="account_select">
                  <div class="select">
                    <select
                      onChange={e => this.onAccountChange(e.target.value)}
                    >
                      {this.props.decision.accounts
                        ? this.props.decision.accounts.map(account => (
                            <option value={account.account_id}>
                              {account.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div class="name">Currently Viewing</div>
                  <div class="select">
                    <select
                      onChange={e =>
                        this.onTransactionTypeChange(e.target.value)
                      }
                    >
                      <option value="all">All</option>
                      <option value="green" selected>
                        Income
                      </option>
                      <option value="debits">Debits</option>
                      <option value="funding_warning">Lenders</option>
                      <option value="payment_warning">Payments</option>
                      <option value="LP">Lenders+Payments</option>
                      <option value="LP Grouped">
                        Lenders+Payments (Grouped)
                      </option>
                      <option value="red gambling">Gambling+Withdrawls</option>
                      <option value="transaction_warning">
                        Suspicious Transactions
                      </option>
                      <option value="nsfs_warning">NSFS</option>
                    </select>
                  </div>
                  <div class="input">
                    <input
                      placeholder="Search Phrase..."
                      value={this.state.searchPhrase}
                      onChange={e => this.onSearchPhrase(e.target.value)}
                    />
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th className="date">
                      <b>Date</b>
                    </th>
                    <th className="name">
                      <b>Name</b>
                    </th>
                    <th className="location">
                      <b>Location</b>
                    </th>
                    <th className="account">
                      <b>Account</b>
                    </th>
                    <th style={{ display: "none" }}>type_controller</th>
                    <th className="amount">
                      <b>Amount</b>
                    </th>
                    <th className="balance">
                      <b>Balance</b>
                    </th>
                  </tr>
                </thead>
                {groupedFormattedTransactionData ? (
                  groupedFormattedTransactionData.map((grouping, idx) => (
                    <tbody key={idx}>
                      {grouping.map(transaction => (
                        <Transaction
                          transaction={transaction}
                          accountName={
                            this.props.decision.accounts.filter(
                              x => x.account_id === this.state.account
                            )[0].name
                          }
                          grouping={true}
                        />
                      ))}
                      <tr class="trans_break"></tr>
                    </tbody>
                  ))
                ) : formattedTransactionData ? (
                  <tbody>
                    {formattedTransactionData.plaid.transactions.map(
                      transaction => (
                        <Transaction
                          transaction={transaction}
                          accountName={
                            this.props.decision.accounts.filter(
                              x => x.account_id === this.state.account
                            )[0].name
                          }
                          grouping={false}
                        />
                      )
                    )}
                  </tbody>
                ) : null}
              </table>
            </div>
            {this.props.decision.ibv_token ? (
              <iframe
                title={this.props.decision.ibv_token}
                src={`https://creditserver.microbilt.com/WebServices/gethtml/gethtml.aspx?guid=${this.props.decision.ibv_token}`}
                class="iframe_full subcontainer"
              ></iframe>
            ) : (
              "No valid IBV (Deprecated)"
            )}
            <div class="clear"></div>
          </div>
          <div class="close_data close" id="close"></div>
          <div class="clear"></div>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  expandDecision: (id, idType, onError) =>
    dispatch(expandDecision(id, idType, onError))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnderwritingLoanData);
