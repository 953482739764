import { combineReducers } from "redux";
import texter from "./texter_reducer";
import user from "./users_reducer";
import loans from "./loans_reducer";
import loan_payments from "./loan_payments_reducer";
import tasks from "./tasks_reducer";
import tickets from "./tickets_reducer";
import identifications from "./identifications_reducer";
import macros from "./macros_reducer";
import underwriting_decisions from "./underwriting_decisions_reducer";
import incomeVerificationReducer from '../features/incomeVerification/incomeVerificationSlice'
import creditReportAdjustmentsReducer from '../features/creditReportAdjustments/creditReportAdjustmentsSlice'
import fcraReducer from "../features/fcra/fcraSlice";

const entitiesReducer = combineReducers({
  texter,
  user,
  loans,
  loan_payments,
  tasks,
  identifications,
  underwriting_decisions,
  macros,
  tickets,
  incomeVerification: incomeVerificationReducer,
  creditReportAdjustments: creditReportAdjustmentsReducer,
  fcra: fcraReducer
});

export default entitiesReducer;
