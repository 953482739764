import React, { Component } from "react";
import moment from "moment";

export default class TransactionListView extends Component {
  relevantTransactionTags = (transaction) => {
    const tags = transaction.transactionTags;
    const considerableTags = [this.props.selectedTag.staticTag, ...(this.props.selectedTag.dynamicTags || [])].filter(x=>x);
    return considerableTags.filter(x=>tags.includes(x));
  }

  shouldBeMutable = (transaction) => {
    return this.relevantTransactionTags(transaction).length > 0 && !transaction.transactionTags.includes(this.props.tagToMutate);
  }

  sortTransactions = (transactions) => {
    return transactions.sort((a, b) => {
      return a.date.localeCompare(b.date) || a.transaction_id.localeCompare(b.transaction_id);
    });
  }

  transactionTableContent = () => {
    if (this.props.transactions.length > 0) {
      return this.sortTransactions(this.props.transactions).map(transaction => (
        <tr key={transaction.transaction_id}
            className={(transaction.transactionTags.includes(this.props.tagToMutate) && "selected") ||
            (this.relevantTransactionTags(transaction).length > 0 && "alreadyselected")}>
          <td><input key={transaction.transaction_id} type="checkbox"
          onClick={(e) => this.props.transactionSelected(transaction)}
          checked={this.relevantTransactionTags(transaction).length > 0}
          disabled={this.shouldBeMutable(transaction)}/></td>
          <td>{transaction.name}</td>
          <td className={`amount ${(transaction.amount >= 0 ? "positive" : "negative")}`}>${transaction.amount.toFixed(2)}</td>
          <td className="date" >{moment(transaction.date).format("(ddd) MM/DD/YYYY")}</td>
        </tr>
      ));
    }
    return (
      <tr>
        <td colspan="4">
        {this.props.transactions.length} total transactions; all filtered
        </td>
      </tr>
    );
  }

  render() {
    return (
      <div class="frozen_header_table">
        <div>
          <div>
            <table>
              <thead>
                <tr>
                  <th><div label=""></div></th>
                  <th><div label="Name"></div></th>
                  <th><div label="Amount"></div></th>
                  <th><div label="Date"></div></th>
                  <th class="scrollbarhead"></th>
                </tr>
              </thead>
              <tbody>
                {this.transactionTableContent()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}