import React, { Component } from "react";

export default class TransactionTagView extends Component {
  constructor(props) {
    super(props)
  }

  selected = (tag) => {
    return tag == this.props.selectedCustomTag;
  }

  transactionTableForTag = (tag) => {
    const transactionsInGroup = this.props.transactions.filter(x => x.transactionTags.includes(tag))
    if (!tag.confirmed) {
      return (
        <table class="transaction_table">
          {transactionsInGroup.map((tx)=>{
            return (
              <tr>
                <td class="transaction_cell">
                  {tx.name}
                </td>
              </tr>
              );
          })}
        </table>
      )
    } else {
      // Collapse transactions into one line + truncate since this group has already been reviewed.
      return (
        <p>{transactionsInGroup.map(x => x.name).join(', ')}</p>
      )
    }
  }

  changeClassification = (tag, classification) => {
    tag.classification = classification;
    this.forceUpdate();
  }

  transactionGroupTableContent = () => {
    if (!this.props.selectedTag.dynamicTags || this.props.selectedTag.dynamicTags.length == 0) {
      return (
        <tr>
          <td>No groups yet!</td>
        </tr>
      );
    } else {
      return this.props.selectedTag.dynamicTags.map((tag) => {
        return (
        <tr className={(this.selected(tag) && "selected") || (tag.confirmed && "confirmed")}>
          <td>
            <div>
              {tag.tagName}
              {this.selected(tag) ? (<button onClick={(e)=>this.props.confirmGroup(tag)}>Save</button>) :
              (<button onClick={(e)=>this.props.selectGroup(tag)}>Edit</button>)}
              <button onClick={(e)=> { if (window.confirm(`Are you sure you'd like to delete ${tag.tagName}?`)) this.props.deleteGroup(tag)}}>Delete</button>
              <select value={tag.classification} disabled={!this.selected(tag)} onChange={(e)=>this.changeClassification(tag, e.target.value)}>
                {this.props.bucketTypes.map(x => {
                  return (<option value={x}>{x}</option>)
                })}
              </select>
            </div>
            <div>
              {this.transactionTableForTag(tag)}
            </div>
          </td>
        </tr>
        );
      });
    }
  }

  render() {
    return (
      <div className="frozen_header_table">
        <div>
          <div>
            <table>
              <thead>
                <tr>
                  <th><div label="Groups"></div></th>
                  <th class="scrollbarhead"></th>
                </tr>
              </thead>
              <tbody>
                <tr><button onClick={this.props.createGroup}>Create</button></tr>
                {this.transactionGroupTableContent()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}