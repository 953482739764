import React, { Component } from "react";
import TransactionListView from "./TransactionListView"
import TransactionTagView from "./TransactionTagView"
import TransactionGroupView from "./TransactionGroupView"
import TransactionFilterView from "./TransactionFilterView"
import "../../styles/css/plaid_classification.css"

export default class PlaidClassificationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomTag: null
    };
  }

  // tagToMutate pulls the tag that we should be modifying, demuxing static and dynamic
  // tags. If the model's currently selected tag is static, we will always return the
  // associated staticTag; if it is dynamic, then the currently-selected tag is handled by
  // our state, so we rely on the user selecting groups to provide us with the tag to mutate.
  _tagToMutate = () => {
    return this.props.selectedTag.staticTag || this.state.selectedCustomTag;
  }

  _transactionsInTagToMutate = () => {
    return this.props.transactions.filter(x => x.transactionTags.includes(this._tagToMutate()));
  }

  // renderableTransactions returns transactions that need to be shown in the list view; this
  // accounts for filtering, etc. This is done here so that we can reuse the method for things
  // like select all
  renderableTransactions = () => {
    const filterState = this.props.filterState;
    const parsedMin = parseFloat(filterState.minAmount);
    const parsedMax = parseFloat(filterState.maxAmount);
    let filterRegex = new RegExp(filterState.nameFilter, "i");
    let amountFilter = x => (isNaN(parsedMin) || x.amount >= parsedMin) && (isNaN(parsedMax) || x.amount <= parsedMax);

    // Grouping filter: if enabled, want to show
    // - Transactions that have no relevant transaction tags
    // - Transactions that have the curretnly-selected transaction tag, since this filter would
    //   otherwise make it incredibly hard to interact with the selected group
    const tagsOfInterest = [this.props.selectedTag.staticTag, this.props.selectedTag.dynamicTags].flat().filter(x=>x && x != this._tagToMutate());
    let groupedFilter = x => (filterState.showGrouped || x.transactionTags.filter(tag=>tagsOfInterest.includes(tag)).length == 0);

    return this.props.transactions.filter(x => { return x.name.match(filterRegex) && amountFilter(x) && groupedFilter(x) });
  }

  ////////////////////////////////// Transaction selection //////////////////////////////////
  // toggleCheckbox is triggered when the user clicks on a transaction's checkbox; it will
  // add the transaction to the currently-mutating tag (or remove if it's already present)
  toggleCheckbox = transaction => {
    if (transaction.transactionTags.includes(this._tagToMutate())) {
      transaction.transactionTags = transaction.transactionTags.filter(x=>x != this._tagToMutate());
    } else {
      transaction.transactionTags.push(this._tagToMutate());
    }

    this.forceUpdate();
  }

  // toggleSelect controls "select all" behavior; we can either (1) select ALL transactions,
  // which adds them to the currently-mutating tag if they are not part of it already, or (2)
  // unselect all transactions, which removes the currently-mutating tag from all transactions.
  // "All" transactions in this context specifically refers to visible ones: so the user can
  // filter and select all to easily select everything with a like name, for example.
  toggleSelect = sel => {
    this.renderableTransactions().forEach(transaction => {
      if (transaction.transactionTags.length > 0 && !transaction.transactionTags.includes(this._tagToMutate())) {
        return;
      }

      if (sel && !transaction.transactionTags.includes(this._tagToMutate())) {
        transaction.transactionTags.push(this._tagToMutate());
      } else if (!sel) {
        transaction.transactionTags = transaction.transactionTags.filter(x=>x != this._tagToMutate());
      }
    });

    this.forceUpdate();
  }

  ////////////////////////////////// Tag management //////////////////////////////////
  // All tag management has to do with custom groups, or "dynamic" tags; tags that are
  // "static" or single-group will not allow the user to create dynamic tags (the component
  // just will not be rendered)
  //
  // createGroup handles creating a new custom tag; it will dispatch a call to the model
  // to create a tag, and select that tag to be the new currently-mutating tag.
  createGroup = () => {
    const tag = this.props.createTag();
    this.setState({selectedCustomTag: tag});
  }

  // selectGroup modifies the currently-mutating tag to the newly selected tag.
  selectGroup = (groupName) => {
    if (!this.props.selectedTag.dynamicTags ||
        !this.props.selectedTag.dynamicTags.includes(groupName)) {
      // something weird going on here
      return;
    }
    this.props.confirmTag(groupName, false)
    this.setState({selectedCustomTag: groupName});
  }

  // deleteGroup removes a tag. This requires a call to be dispatched to the model, which
  // will (1) get rid of the tag w/in dynamicTags and (2) remove the tag from any transactions
  // that have already been classified with it; we also will update our currently-mutating tag
  // if we were mutating the tag that was removed. We will select the tag that is closest to
  // the previous index so that it's not visually jarring.
  deleteGroup = (tag) => {
    if (!this.props.selectedTag.dynamicTags
      || !this.props.selectedTag.dynamicTags.includes(tag)) {
      // something weird going on here
      return;
    }
    this.props.deleteTag(tag);

    if (this.state.selectedCustomTag) {
      this.setState({
        selectedCustomTag: this.state.selectedCustomTag == tag ? null : this.state.selectedCustomTag
      })
    }
  }

  confirmGroup = (groupName) => {
    if (this._transactionsInTagToMutate().length == 0) {
      alert("There are no transactions in this group. Please add some or delete the group!");
      return;
    }
    this.props.confirmTag(groupName, true)
    // unselect the currently selected tag if we're in custom group view
    this.setState({selectedCustomTag: null});
  }

  render() {
    return (
      <div className="classify_container">
        <div className="classify_left">
          <h2>Transactions</h2>
          <button onClick={(e) => this.toggleSelect(true)}>
            Select all
          </button>
          <button onClick={(e) => this.toggleSelect(false)}>
            Unselect all
          </button>
          <TransactionListView transactions={this.renderableTransactions()}
            transactionSelected={this.toggleCheckbox}
            selectedTag={this.props.selectedTag}
            tagToMutate={this._tagToMutate()} />
        </div>
        <div className="classify_right">
          <TransactionFilterView filterState={this.props.filterState}
            mutateState={this.props.issueFilterUpdate} />
          {!!this.props.selectedTag.staticTag ?
            <TransactionTagView transactions={this.props.transactions}
              selectedGroup={this._tagToMutate()} /> :
            <TransactionGroupView transactions={this.props.transactions}
              bucketTypes={this.props.bucketTypes}
              selectedTag={this.props.selectedTag}
              selectedCustomTag={this._tagToMutate()}
              createGroup={this.createGroup}
              selectGroup={this.selectGroup}
              deleteGroup={this.deleteGroup}
              confirmGroup={this.confirmGroup} />}
        </div>
        <div className="footer">
          {this.props.transactions.length} transactions; {this.props.transactions.filter((x) => x.transactionTags.length == 0).length} untagged
        </div>
      </div>
    );
  }
}
