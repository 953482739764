import React from "react";

const DashboardNav = ({ screen, setScreen }) => {
  return (
    <div className="e-dashboard-navbar">
      <ul className="e-dashboard-navbar-list">
        <li
          onClick={() => setScreen("home")}
          className={`e-dashboard-navbar-item ${
            screen === "home" ? "active" : ""
          }`}
        >
          Dasbboard
        </li>
        <li
          onClick={() => setScreen("resolved")}
          className={`e-dashboard-navbar-item ${
            screen === "resolved" ? "active" : ""
          }`}
        >
          Resolved
        </li>
      </ul>
    </div>
  );
};

export default DashboardNav;
