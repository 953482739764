import React, { Component } from "react";

export default class TransactionTagView extends Component {
  transactionGroupTableContent = () => {
    let filteredTransactions = this.props.transactions.filter(tx=>tx.transactionTags.includes(this.props.selectedGroup));
    if (filteredTransactions.length > 0) {
        return filteredTransactions.map(tx => (
            <tr>
              <td>
                {tx.name}
              </td>
            </tr>
          ));
    } else {
      return (
        <tr>
          <td>
            No transactions tagged!
          </td>
        </tr>
      );
    }
  }

  render() {
    return (
      <div className="frozen_header_table">
        <div>
          <div>
            <table>
              <thead>
                <tr>
                  <th><div label="Transactions"></div></th>
                  <th class="scrollbarhead"></th>
                </tr>
              </thead>
              <tbody>
                {this.transactionGroupTableContent()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}