import React, { useState, useEffect } from "react";
import employees from "./employees";
import moment from "moment";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { motion } from "framer-motion";

const ErrorTable = ({ resolveError, currentUser, errors }) => {
  return (
    <table className="e-dashboard-table">
      <thead className="e-dashboard-head">
        <tr className="e-dashboard-row head">
          <td className="e-dashboard-data">Warning</td>
          <td className="e-dashboard-data">Instruction</td>
          <td className="e-dashboard-data">Asignee</td>
          <td className="e-dashboard-data">Loan ID(s)</td>
          <td className="e-dashboard-data">Count</td>
        </tr>
      </thead>
      <tbody className="e-dashboard-body">
        {errors && Object.keys(errors).length > 0
          ? Object.keys(errors).map((key, i) => {
              return (
                <WarningRow
                  key={i}
                  resolveError={resolveError}
                  employeeID={currentUser.employeeID}
                  index={i}
                  errorArray={errors[key]}
                  title={key}
                />
              );
            })
          : null}
      </tbody>
    </table>
  );
};

export default ErrorTable;

const WarningRow = ({ resolveError, employeeID, errorArray, title, index }) => {
  const [showModal, setShowModal] = useState(false);

  // Used for resolving errors in the modal
  const [selectedWarnings, setSelectedWarnings] = useState({});

  // Toggles display of error resolution success message
  const [resolutionSuccess, setResolutionSuccess] = useState(false);

  const toggleModal = () => {
    if (!showModal) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "visible";
    }
    setShowModal((s) => !s);
  };

  // Function is used to create array of selected error based on selected object and then send out batch for resolution
  const handleErrorResolution = async () => {
    if (Object.values(selectedWarnings).includes(true)) {
      let error_id_array = [];
      Object.keys(selectedWarnings).map((key) => {
        if (selectedWarnings[key]) {
          error_id_array.push(parseInt(key));
        }
      });

      await resolveError(employeeID, error_id_array, handleResolutionStatus);
    }
  };

  const handleResolutionStatus = (payload) => {
    if (payload.success) {
      showSuccessMessage();
      toggleModal();
    }
  };

  const showSuccessMessage = () => {
    setResolutionSuccess(true);

    setTimeout(() => {
      if (resolutionSuccess) {
        setResolutionSuccess(false);
      }
    }, 3000);
  };

  if (errorArray && errorArray.length >= 1) {
    return (
      <>
        {errorArray?.map((error, i) => {
          if (i < 1) {
            return (
              <>
                <tr
                  key={index}
                  className={`e-dashboard-row body ${
                    index % 2 === 0 ? "gray" : ""
                  }`}
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  <td className="e-dashboard-data">{title}</td>
                  <td className="e-dashboard-data">{error.instruction}</td>
                  <td className="e-dashboard-data">
                    {getEmployeeName(error.employee_id)}
                  </td>
                  <td className="e-dashboard-data">{`${error.loan_id}`}</td>
                  <td className="e-dashboard-data">{errorArray.length}</td>
                </tr>
                {showModal && (
                  <div
                    onClick={(s) => {
                      toggleModal();
                    }}
                    className="e-dashboard-overlay"
                  >
                    <div
                      className="e-dashboard-modal"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="e-dashboard-modal-close">
                        <p
                          onClick={() => {
                            toggleModal();
                          }}
                          className="e-dashboard-modal-close-text"
                        >
                          X
                        </p>
                      </div>
                      <h3 className="e-dashboard-modal-title">
                        Warning Details
                      </h3>
                      <table className="e-dashboard-table">
                        <tr className="e-dashboard-row expanded">
                          <td className="e-dashboard-data key">Warning</td>
                          <td className="e-dashboard-data value">
                            {error.error}
                          </td>
                        </tr>
                        <tr className="e-dashboard-row expanded">
                          <td className="e-dashboard-data key ">Instruction</td>
                          <td className="e-dashboard-data value">
                            {error.instruction}
                          </td>
                        </tr>
                        <tr className="e-dashboard-row expanded">
                          <td className="e-dashboard-data key ">Loan ID(s)</td>
                          <td className={`e-dashboard-data value`}>
                            {`${error.loan_id}`}
                          </td>
                        </tr>

                        <tr className="e-dashboard-row expanded">
                          <td className="e-dashboard-data key ">Asignee</td>
                          <td className="e-dashboard-data value">
                            {getEmployeeName(error.employee_id)}
                          </td>
                        </tr>
                        <tr className="e-dashboard-row expanded">
                          <td className="e-dashboard-data key ">Date</td>
                          <td className="e-dashboard-data value">
                            {moment(error.created_at).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </td>
                        </tr>
                        <tr className="e-dashboard-row expanded">
                          <td className="e-dashboard-data key ">Status</td>
                          <td className="e-dashboard-data value status">
                            {error.open ? (
                              <AiFillCloseCircle
                                style={{ color: "#e37262", fontSize: "1.2rem" }}
                              />
                            ) : (
                              <AiFillCheckCircle
                                style={{ color: "#3fb39d", fontSize: "1.2rem" }}
                              />
                            )}
                          </td>
                        </tr>
                        {errorArray.length >= 1 && (
                          <>
                            <tr className="e-dashboard-row trace">
                              <table className="e-dashboard-duplicate-display">
                                <thead className="e-dashboard-head">
                                  <tr className="e-dashboard-row head">
                                    <td className="e-dashboard-data">Select</td>
                                    <td className="e-dashboard-data">
                                      Warning
                                    </td>
                                    <td className="e-dashboard-data">
                                      Instruction
                                    </td>
                                    <td className="e-dashboard-data">
                                      Asignee
                                    </td>
                                    <td className="e-dashboard-data">
                                      Loan ID
                                    </td>
                                  </tr>
                                </thead>
                                <tbody className="e-dashboard-body">
                                  {errorArray.length >= 1
                                    ? errorArray.map((warning, i) => {
                                        return (
                                          <TraceRow
                                            warning={warning}
                                            i={i}
                                            title={title}
                                            selectedWarnings={selectedWarnings}
                                            setSelectedWarnings={
                                              setSelectedWarnings
                                            }
                                          />
                                        );
                                      })
                                    : null}
                                </tbody>
                              </table>
                            </tr>
                          </>
                        )}
                        <tr className="e-dashboard-modal-controls">
                          <td>
                            <motion.button
                              onClick={() => {
                                handleErrorResolution();
                              }}
                              whileTap={
                                !!Object.values(selectedWarnings).includes(true)
                                  ? { scale: 0.95 }
                                  : null
                              }
                              className={
                                Object.values(selectedWarnings).includes(true)
                                  ? "e-dashboard-button"
                                  : "e-dashboard-button disabled"
                              }
                            >
                              Resolve Selected
                            </motion.button>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                )}
                {resolutionSuccess && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="e-dashboard-success-container"
                  >
                    <div className="e-dashboard-success-message">
                      Error Resolved Successfully
                    </div>
                  </motion.div>
                )}
              </>
            );
          }
        })}
      </>
    );
  }

  return null;
};

const TraceRow = ({
  warning,
  i,
  selectedWarnings,
  setSelectedWarnings,
  title,
}) => {
  const [showTrace, setShowTrace] = useState(false);

  const toggleTrace = () => {
    if (!showTrace) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "visible";
    }
    setShowTrace((s) => !s);
  };

  return (
    <>
      <tr
        onDoubleClick={() => toggleTrace()}
        key={i}
        className={`e-dashboard-row body ${i % 2 === 0 ? "gray" : ""}`}
      >
        <td className="e-dashboard-data">
          <input
            checked={selectedWarnings[warning.id]}
            type="checkbox"
            className="e-dashboard-checkbox"
            onChange={() =>
              // When an error is selected, its id is added to the selectedWarnings object. When it is deselected, rather than checking for existence and deleting, we simply set it's new value to
              setSelectedWarnings({
                ...selectedWarnings,
                [warning.id]: !selectedWarnings[warning.id],
              })
            }
          />
        </td>
        <td className="e-dashboard-data">{title}</td>
        <td className="e-dashboard-data">{warning.instruction}</td>
        <td className="e-dashboard-data">
          {getEmployeeName(warning.employee_id)}
        </td>
        <td className="e-dashboard-data">{warning.loan_id}</td>
      </tr>
      {showTrace && (
        <div
          onClick={(s) => {
            toggleTrace();
          }}
          className="e-dashboard-overlay"
        >
          <div
            className="e-dashboard-modal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="e-dashboard-modal-close">
              <p
                onClick={() => {
                  toggleTrace();
                }}
                className="e-dashboard-modal-close-text"
              >
                X
              </p>
            </div>
            <h3 className="e-dashboard-modal-title">Additional Info</h3>
            <p className="e-dashboard-trace-container">
              {warning.trace ? warning.trace : "N/A"}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

const getEmployeeName = (e_id) => {
  return Object.keys(employees).find((key) => employees[key] === e_id);
};
