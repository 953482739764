import { domain } from "../helpers/route_helper";

export const getErrorWarnings = () => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/get_error_warnings`,
  });
};

export const getClosedWarnings = (closer_id) => {
  return window.$.ajax({
    method: "GET",
    data: {
      closer_id,
    },
    url: `${domain}/api/get_closed_warnings`,
  });
};

export const resolveError = (closed_by, error_ids) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/api/close_error_warnings`,
    data: {
      closed_by,
      error_ids,
      close_date: new Date().toISOString(),
    },
  });
};
