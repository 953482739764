import * as APIUtil from "../util/analytics_api_util";
export const RECEIVE_ANALYTICS = "RECEIVE_ANALYTICS";

export const receiveAnalytics = analytics => ({
  type: RECEIVE_ANALYTICS,
  analytics
});

export const getAnalytics = (reg_regime, startDate, endDate, cb) => dispatch =>
  APIUtil.getAnalytics(reg_regime, startDate, endDate, cb).then(analytics => {
    dispatch(receiveAnalytics(analytics));
    cb();
  });

export const getMarketingAnalytics = (startDate, endDate, cb) => dispatch =>
  APIUtil.getMarketingAnalytics(startDate, endDate, cb).then(analytics => {
    dispatch(receiveAnalytics(analytics));
    cb();
  });
