import * as APIUtil from "../util/underwriting_api_util";
export const RECEIVE_UNDERWRITING_DECISIONS = "RECEIVE_UNDERWRITING";
export const UPDATE_UNDERWRITING_DECISION = "UPDATE_DECISION";
export const COMPLETE_UNDERWRITING_DECISION = "COMPLETE_DECISION";
export const EDIT_UNDERWRITING_DECISION = "EDIT_DECISION";

export const receiveUnderwritingDecisions = underwritingDecisions => ({
  type: RECEIVE_UNDERWRITING_DECISIONS,
  underwritingDecisions
});

export const updateUnderwritingDecision = underwritingDecision => ({
  type: UPDATE_UNDERWRITING_DECISION,
  underwritingDecision
});

export const editUnderwritingDecision = (underwritingDecision, editedAttr) => ({
  type: EDIT_UNDERWRITING_DECISION,
  underwritingDecision,
  editedAttr
});

export const completeUnderitingDecision = id => ({
  type: COMPLETE_UNDERWRITING_DECISION,
  id
});

export const fetchUnderwritingDecisions = callback => dispatch =>
  APIUtil.fetchUnderwritingDecisions().then(underwritingDecisions => {
    dispatch(receiveUnderwritingDecisions(underwritingDecisions));
    const ids = underwritingDecisions.normal_decisions ? Object.keys(underwritingDecisions.normal_decisions) : [];
    callback(ids);
  });

export const editLoan = (id, key, val, onError) => dispatch =>
  APIUtil.editLoan(id, key, val).then(
    underwritingDecision => {
      dispatch(editUnderwritingDecision(underwritingDecision, key));
    },
    error => onError(key)
  );

export const expandDecision = (id, id_type, onError) => dispatch =>
  APIUtil.expandDecision(id, id_type).then(
    underwritingDecision => {
      dispatch(updateUnderwritingDecision(underwritingDecision));
    },
    error => onError()
  );

export const loanAction = (
  loan_id,
  action,
  comment,
  amount,
  experiment,
  decision_id,
  callback
) => dispatch =>
  APIUtil.loanAction(loan_id, action, comment, amount, experiment).then(() => {
    dispatch(completeUnderitingDecision(decision_id));
    callback();
  });
