import React from "react";
import Search from "./Search_container";
import { signOut } from "../features/sessions/sessionSlice";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import "../styles/css/navbar.css";

const Navbar = ({ history, currentUser }) => {
  const dispatch = useDispatch();
  
  const pusher = (path) => {
    if (history.location.pathname !== path) history.push(path);
  };

  return (
    <div className="navbar">
      {currentUser?.clearance !== "f" ? <Search /> : <span />}
      <ul>
        {currentUser?.clearance !== "f"
          ? [
              <li key={1} onClick={() => pusher("/")}>
                Home
              </li>,
              <li key={2} onClick={() => pusher("/blog_post")}>
                Blog Post
              </li>,
              <li key={3} onClick={() => pusher("/analytics")}>
                Analytics
              </li>,
              <li
                key={4}
                className="dropdown"
                onMouseEnter={(e) => {
                  let boundingClientRect = e.target.getBoundingClientRect();
                  document.getElementsByClassName(
                    "dropdown-content"
                  )[0].style.left = boundingClientRect.x + "px";
                  document.getElementsByClassName(
                    "dropdown-content"
                  )[0].style.top =
                    boundingClientRect.y + boundingClientRect.height + 2 + "px";
                }}
              >
                <span className="dropdown-btn">Visualize</span>
                <div className="dropdown-content">
                  <li
                    onClick={() => {
                      pusher("/visualize/cs");
                    }}
                  >
                    CS
                  </li>
                  <li onClick={() => pusher("/visualize/vintage")}>Vintage</li>
                  <li onClick={() => pusher("/sankey")}>UW Flow</li>
                  <li onClick={() => pusher("/errors")}>Errors</li>
                </div>
              </li>,
            ]
          : null}
        <li onClick={() => pusher("/marketing")}>Marketing</li>
        <li
          className="dropdown"
          onMouseEnter={(e) => {
            let boundingClientRect = e.target.getBoundingClientRect();
            document.getElementsByClassName("dropdown-content")[0].style.left =
              boundingClientRect.x + "px";
            document.getElementsByClassName("dropdown-content")[0].style.top =
              boundingClientRect.y + boundingClientRect.height + 2 + "px";
          }}
        >
          <span className="dropdown-btn">UW</span>
          <div className="dropdown-content">
            <li
              onClick={() => {
                pusher("/dashboard");
              }}
            >
              Dashboard
            </li>
            <li onClick={() => pusher("/loan_review")}>Loan Review</li>
            <li onClick={() => pusher("/plaid_review")}>Plaid Review</li>
          </div>
        </li>{" "}
        <li onClick={() => dispatch(signOut())}>Log Out</li>
      </ul>
    </div>
  );
};

export default withRouter(Navbar);
