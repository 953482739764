import { merge } from "lodash";
import { RECEIVE_NUMBERS, RECEIVE_TEXTS } from "../actions/text_actions";

const defaultProps = {
  unreadNumbers: [],
  supplementalNumbers: [],
  numberCount: 0,
  unreadConversationsCount: 0,
  unaddressedNumbers: [],
  texts: {},
  guesses: []
};

const texterReducer = (oldState = defaultProps, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_NUMBERS:
      return merge(
        {},
        {
          numberCount: oldState.numberCount,
          unreadConversationsCount: oldState.unreadConversationsCount,
          texts: oldState.texts,
          guesses: oldState.guesses
        },
        {
          unreadNumbers: action.numbers.unreadNumbers,
          supplementalNumbers: action.numbers.supplementalNumbers,
          numberCount: action.numbers.numberCount,
          unreadConversationsCount: action.numbers.unreadConversationsCount,
          unaddressedNumbers: action.numbers.unaddressedNumbers
        }
      );
    case RECEIVE_TEXTS:
      return merge(
        {},
        {
          numberCount: oldState.numberCount,
          unreadNumbers: oldState.unreadNumbers,
          supplementalNumbers: oldState.supplementalNumbers,
          unaddressedNumbers: oldState.unaddressedNumbers
        },
        {
          texts: action.payload.texts,
          unreadConversationsCount:
            action.payload.unreadConversationsCount ||
            oldState.unreadConversationsCount,
          guesses: action.payload.guesses
        }
      );
    default:
      return oldState;
  }
};

export default texterReducer;
