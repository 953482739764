import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";

import { AnimatePresence, motion } from "framer-motion";

import moment from "moment";

import { connect } from "react-redux";

import {
  getErrorWarnings,
  resolveError,
  getResolvedWarnings,
} from "../../actions/error_db_actions";

import DashboardNav from "./DashboardNav";
import ErrorView from "./ErrorView";

function ErrorDashboard({
  resolveError,
  currentUser,
  getErrorWarnings,
  getResolvedWarnings,
  resolvedWarnings,
  warnings,
}) {
  // Actual errors in code
  const [errors, setErrors] = useState([]);

  const [thisDay, setThisDay] = useState([]);
  const [thisWeek, setThisWeek] = useState([]);
  const [thisMonth, setThisMonth] = useState([]);

  // Used to store user specific errors
  const [userErrors, setUserErrors] = useState(null);

  // Used to handle dashboard view controller
  const [screen, setScreen] = useState("home");

  // This useEffect is used to filter all available errors and only extract errors specific to currently logged in user.
  useEffect(() => {
    let temp = [];
    if (Object.keys(warnings).length >= 1 && !userErrors) {
      Object.keys(warnings).map((key) => {
        if (
          warnings[key].employee_id === parseInt(currentUser.employeeID, 10)
        ) {
          temp.push(warnings[key]);
        }
      });
    }

    if (temp.length >= 1) {
      setUserErrors(collapseWarningDuplicates(temp));
    }
  }, [warnings]);

  // If user requests resolved errors, fetch resolved
  useEffect(() => {
    if (screen === "resolved" && Object.keys(resolvedWarnings).length < 1) {
      getResolvedWarnings(11, (e) => console.log(e));
    }
  }, [screen]);

  // If warnings do not exist, get them and send to Redux
  useEffect(() => {
    if (Object.keys(warnings).length < 1) {
      getErrorWarnings(setErrors);
    }
  }, [warnings]);

  // Once warnings exist, we need to sort them
  useEffect(() => {
    if (Object.keys(warnings).length >= 1) {
      sortErrors(warnings);
    }
  }, [warnings]);

  const sortErrors = async (warnings) => {
    let warningsDay = [];
    let warningsWeek = [];
    let warningsMonth = [];

    Object.values(warnings).map((warning) => {
      if (warning.employee_id !== parseInt(currentUser.employeeID, 10)) {
        let hours = moment().diff(moment(warning.created_at), "hour", true);

        if (hours < 24) {
          warningsDay.push(warning);
          return;
        }

        if (hours < 168) {
          warningsWeek.push(warning);
          return;
        }

        if (hours < 720) {
          warningsMonth.push(warning);
          return;
        }
      }
    });

    setThisDay(collapseWarningDuplicates(warningsDay));
    setThisWeek(collapseWarningDuplicates(warningsWeek));
    setThisMonth(collapseWarningDuplicates(warningsMonth));
  };

  // Alters warnings from array of objects to object of arrays
  const collapseWarningDuplicates = (errors) => {
    // Take in error objec
    // Sort error objects into organized object structure {title : [array of errors matching title], title: [...]}

    let tempWarningsObj = {};

    errors.map((error) => {
      if (tempWarningsObj[error.error]) {
        tempWarningsObj[error.error].push(error);
      } else {
        tempWarningsObj[error.error] = [error];
      }
    });

    return tempWarningsObj;
  };

  return (
    <main>
      <Navbar />
      <section className="e-dashboard">
        <DashboardNav screen={screen} setScreen={setScreen} />
        <ErrorDisplay errors={errors} setErrors={setErrors} />
        {screen === "home" && (
          <div className="e-dashboard-container">
            <ErrorView
              resolveError={resolveError}
              currentUser={currentUser}
              errors={userErrors}
              title={"For You"}
            />
            <ErrorView
              resolveError={resolveError}
              currentUser={currentUser}
              errors={thisDay}
              title={"Last 24 Hours"}
            />
            <ErrorView
              resolveError={resolveError}
              currentUser={currentUser}
              errors={thisWeek}
              title={"Last 7 Days"}
            />
            <ErrorView
              resolveError={resolveError}
              currentUser={currentUser}
              errors={thisMonth}
              title={"Last 30 Days"}
            />
          </div>
        )}
        {screen === "resolved" && resolvedWarnings && (
          <ErrorView
            resolveError={resolveError}
            currentUser={currentUser}
            errors={resolvedWarnings}
            title={"Resolved Errors"}
          />
        )}
      </section>
    </main>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  warnings: state.errors.warnings,
  resolvedWarnings: state.errors.resolvedWarnings,
});

const mapDispatchToProps = (dispatch) => ({
  getErrorWarnings: (callback) => dispatch(getErrorWarnings(callback)),
  resolveError: (closed_by, error_id, callback) =>
    dispatch(resolveError(closed_by, error_id, callback)),
  getResolvedWarnings: (closer_id, callback) =>
    dispatch(getResolvedWarnings(closer_id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDashboard);

// Error Display for Operational Errors (400 / 500 Responses, etc.)
const ErrorDisplay = ({ errors, setErrors }) => {
  useEffect(() => {
    setTimeout(() => {
      setErrors([]);
    }, 3000);
  }, []);

  if (errors && errors.length >= 1) {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ y: -1000 }}
          animate={{ y: 0 }}
          className="e-dashboard-error-display"
          exit={{ y: -1000 }}
        >
          {errors[0]}
        </motion.div>
      </AnimatePresence>
    );
  }

  return <div></div>;
};
