import * as APIUtil from "../util/text_api_util";
// import { receiveUser } from "./user_actions";
// import { receiveCurrentUser } from "./session_actions";
// import { receiveUser } from "./user_actions";
import { receiveLoans } from "./loan_actions";
import { receiveLoading } from "./loader_actions";
export const RECEIVE_NUMBERS = "RECEIVE_NUMBERS";
export const RECEIVE_TEXTS = "RECEIVE_TEXTS";

export const receiveNumbers = numbers => ({
  type: RECEIVE_NUMBERS,
  numbers
});

export const receiveTexts = payload => ({
  type: RECEIVE_TEXTS,
  payload
});

export const fetchNumbers = count => dispatch =>
  APIUtil.fetchNumbers(count)
    .then(numbers => dispatch(receiveNumbers(numbers)))
    // .catch(() => dispatch(receiveCurrentUser(null)));

export const fetchTexts = number => dispatch =>
  APIUtil.fetchTexts(number).then(payload => {
    dispatch(receiveTexts(payload));
    // dispatch(receiveUser(payload.user));
    dispatch(receiveLoans(payload.loans));
    dispatch(receiveLoading(false));
  });

export const sendText = (number, body) => dispatch =>
  APIUtil.sendText(number, body).then(payload =>
    dispatch(receiveTexts(payload))
  );

export const search = id => dispatch =>
  APIUtil.search(id).then(payload => dispatch(receiveNumbers(payload)));

export const markUnread = (number, count) => dispatch =>
  APIUtil.markUnread(number, count).then(payload =>
    dispatch(receiveNumbers(payload))
  );

export const fetchUnaddressed = () => dispatch =>
  APIUtil.fetchUnaddressed()
    .then(numbers => dispatch(receiveNumbers(numbers)))
    // .catch(() => dispatch(receiveCurrentUser(null)));
