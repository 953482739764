import React, {useState, useEffect} from "react";
import {CSSTransition} from 'react-transition-group'
import * as d3 from 'd3'

export default function TagVisualizer(props) {

  const [data, setData] = useState(props.data)
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    if (!isLoading) {
      renderData()
    }
    else {
      d3.select('.chart-svg').selectAll('g').remove()
    }
  }, [isLoading])


  const renderData = () => {
    var chartSVG = d3.select('.chart-svg')
    const margin = {top: 20, right: 10, bottom: 20, left: 10}
    const height = parseInt(chartSVG.style('height'))
    const width = parseInt(chartSVG.style('width'))
    var yAxis = d3.scaleLinear().domain([0, Math.max(...Object.values(data))]).range([height-margin.top, margin.bottom])
    var color = d3.scaleLinear().domain([0,600]).range(["brown", 'steelblue'])
    var y = d3.scaleLinear().domain([0, Math.max(...Object.values(data))]).range([margin.bottom, height-margin.top])
    var x = d3.scaleBand().domain(Object.keys(data)).rangeRound([margin.left, width-margin.right])

    var rectGroups = d3.select('.chart-svg').selectAll('g').data(Object.keys(data), d => d).enter().append('g').attr('transform', (d,i) => `translate(${x(d) + x.bandwidth()/2 -  5}, 0)`)
    rectGroups.append('rect').style("fill", d => color(x(d))).attr('width','20px').attr('height', 0).attr('y', d => height - y(data[d]))
    rectGroups.selectAll('rect').transition().attr('height', (d) => y(data[d]) - margin.bottom).duration(2500)

    rectGroups.append('text').attr('y', d => height - y(data[d])-3).text(d => data[d])


    chartSVG.append('g').attr('transform', `translate(5,${height-margin.bottom})`).call(d3.axisBottom(x).tickSizeOuter(0))
    chartSVG.append('g').attr('transform', `translate(${margin.left+15}, 0)`).call(d3.axisLeft(yAxis))
  }


  return(
    <div className='chart-container'>
      <CSSTransition
        in={true}
        timeout={1000}
        classNames="chart-transition"
        appear
        onEntered={(node, isAppearing)=> {setData(props.data);setIsLoading(false)}}
        onExit={() => {setIsLoading(true)}}
        enter={true}
        >
        <svg className='chart-svg'>
        </svg>
      </CSSTransition>
    </div>
  )
}
