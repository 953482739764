import React, { useState } from "react";

import ErrorTable from "./ErrorTable";

const ErrorView = ({ resolveError, currentUser, errors, title }) => {
  const [expandView, setExpandView] = useState(true);

  return (
    <div className="e-dashboard-section">
      <h2
        onClick={() => {
          setExpandView((s) => !s);
        }}
        className={`e-dashboard-section-title ${
          errors && Object.keys(errors).length >= 1 ? "hoverable" : ""
        }`}
      >
        {title}
      </h2>
      {expandView && errors && Object.keys(errors).length >= 1 ? (
        <ErrorTable
          resolveError={resolveError}
          currentUser={currentUser}
          errors={errors}
        />
      ) : errors && Object.keys(errors).length >= 1 ? null : (
        <p className="e-dashboard-empty">
          {title === "For You"
            ? "There are currently no warnings for you to review!"
            : "There are no warnings in this category"}
        </p>
      )}
    </div>
  );
};

export default ErrorView;
