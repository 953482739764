import React, {useState, useEffect} from 'react';
import { domain } from "../helpers/route_helper";
import AutocompleteSearch from './dashboard/AutocompleteSearch'
import Modal from './Modal'

export default function TagManager(props) {

  const [tagList, setTagList] = useState({})
  const [tagToChange, setTagToChange] = useState('')
  const [changeTo, setChangeTo] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState('')
  const [modalDisplayButtons, setModalDisplayButtons] = useState(false)


  useEffect(() => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/tags/get_tag_list`
    }).then(data => {setTagList(data.tagList)})
  }, [])

  const onAutocompleteInput = (ev) => {
    setTagToChange(ev.target.value)
  }

  const changeTicketTag = () => {
    window.$.ajax({
      method: "PATCH",
      url: `${domain}/internal/api/tags/change_tag_name`,
      data: {oldTag: tagToChange, newTag: changeTo}
    })
    .then(data => {
      var tmpTagList = tagList.filter(tag => tag !== tagToChange)
      !tmpTagList.includes(changeTo) && tmpTagList.push(changeTo)
      setTagList(tmpTagList)
      alert(data.success)
    })
    .catch(error => alert(error.responseJSON.error))
  }

  const openModal = () => {
    if (!tagList.includes(tagToChange)){
      setModalText(`'${tagToChange}' does not exist in the database`)
      setModalDisplayButtons(false)
    }
    else if (changeTo.trim().length === 0) {
      setModalText(`New tag cannot be empty string`)
      setModalDisplayButtons(false)
    }
    else {
      setModalText(`All taggings with tag '${tagToChange}' will be replaced with tag '${changeTo}'. \n\nClick yes to confirm or no to cancel.`)
      setModalDisplayButtons(true)
    }
    setShowModal(true)
  }

  const modalFeedback = (modalResponse) => {
    if (modalResponse === 'yes') {
      changeTicketTag()
    }
    setTagToChange('')
    setChangeTo('')
    setShowModal(false)
    setModalText('')
    setModalDisplayButtons(false)
  }

  return(
    <div className='tag-manager-container'>
      <form className='tag-manager-form' id='tag-manager-form'>
        <div>
          <label>Tag to Change</label>
          <AutocompleteSearch name={'tag-manager'} inputValue={tagToChange} onInput={onAutocompleteInput} selectOptions={tagList} />
        </div>
        <div>
          <label>Change to</label>
          <input onChange={(ev) => setChangeTo(ev.target.value)} type='text' value={changeTo}/>
        </div>
        <button onClick={openModal}type='submit'>Submit</button>
      </form>
      {showModal ? <Modal modalDisplayButtons={modalDisplayButtons} modalFeedback={modalFeedback} text={modalText}/> : null}
    </div>
  )
}
