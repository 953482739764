import React, { Component } from "react";
import Navbar from "../Navbar_container";
import Loader from "react-loader";
import { expandPlaidTransactions, submitPlaidClassification } from "../../util/underwriting_api_util";
import PlaidClassificationView from "./PlaidClassificationView"

export default class PlaidReview extends Component {
  ////////////////////////////////// Constants //////////////////////////////////
  // Tagging schemes: specifies properties of each type of tagging.
  // - displayName controls what the user sees in the tagging scheme dropdown
  // - useCustomGroupings allows the user to create an arbitrary number of
  //   groupings, and submission of this tagging scheme will specify more than
  //   one group of transactions
  // - filters allows the scheme to override default filters, which will be set
  //   when the tag is chosen.
  static taggingSchemes = {
    lenders: {
      displayName: "Lenders",
      useCustomGroupings: false,
      filters: {}
    },
    gambling: {
      displayName: "Gambling",
      useCustomGroupings: false,
      filters: {}
    },
    income: {
      displayName: "Income streams",
      useCustomGroupings: true,
      filters: {
        nameFilter: "",
        minAmount: 0
      }
    }
  };

  // Default tagging scheme -- the tagging scheme we select upon opening the dashboard.
  // For now keep this as income
  static defaultTaggingScheme = "income";

  // Default values for filters. Filters will be reset to defaults when the user changes
  // tagging scheme; any additional filter changes specified by the tagging scheme will be
  // applied on top of these defaults after.
  static defaultFilters = {
    nameFilter: "",
    minAmount: "",
    maxAmount: "",
    showGrouped: true
  };

  constructor(props) {
    super(props);
    this.state = {
      plaidID: null,
      plaidTransactions: null,
      plaidGroups: null,
      loading: false,
      submitting: false,
      selectedTaggingScheme: PlaidReview.defaultTaggingScheme,
      filterState: this.constructFilters(PlaidReview.defaultTaggingScheme)
    };
    this.setupTaggingSchemes();
  };

  ////////////////////////////////// Loading + state mgmt //////////////////////////////////

  componentDidMount() {
    const idPosition = window.location.hash.search("plaid_id");
    if (idPosition >= 0) {
      const queryString = window.location.hash.substring(idPosition);
      const id = new URLSearchParams(queryString).get("plaid_id");
      this.state.plaidID = id
    }
    this.loadPlaidData();
  };

  loadPlaidData = () => {
    this.setState({loading: true});
    expandPlaidTransactions(this.state.plaidID).then(
      x => {
        this.state.plaidTransactions = Object.values(x.transactions);
        this.state.plaidTransactions.forEach(txn => {
          txn.transactionTags = []
        });
        this.state.plaidGroups = x.engine_buckets;
        this.state.plaidID = x.plaid_handler_id;
        this.tagTransactions();
        this.setState({loading: false});
      },
      error => {
        alert(error.responseJSON.error || "Error 500");
        this.setState({loading: false});
      }
    );
  };

  submitClassification = () => {
    if (this.state.submitting) {
      return;
    }

    this.setState({submitting: true});
    const unconfirmedTags = this._getUnconfirmedTags();
    // Check that all tags have been confirmed first
    if (unconfirmedTags.length > 0) {
      alert(`You haven't confirmed all tags; please check: ${unconfirmedTags.map(x=>x.tagName)}.`);
      this.setState({submitting: false});
      return
    }

    const tagsToFilter = this._getTagsOfInterest();
    const classification = {};

    tagsToFilter.forEach(tag => {
      classification[tag.tagName] = {
        transactions: [],
        classification: tag.classification
      }
    });

    this.state.plaidTransactions.forEach(txn => {
      txn.transactionTags.forEach(tag => {
        // Only push to groups that are already existing in the classification. If the tag
        // doesn't exist in the classification, then it's not a tag of interest.
        if (classification[tag.tagName]) {
          classification[tag.tagName].transactions.push(txn.transaction_id)
        }
      });
    });

    // todo: point this to an endpoint on backend to create model
    submitPlaidClassification(this.state.plaidID, this.state.selectedTaggingScheme, JSON.stringify(Object.values(classification))).then(
      x => {
        // do nothing
      },
      error => {
        alert(`Saving failed; error: ${error.responseJSON.error}`);
      }
    ).then(() => this.setState({submitting: false}));
  }

  ////////////////////////////////// Tagging scheme mgmt //////////////////////////////////

  constructFilters = (scheme) => {
    // Change filter state:
    // 1. reset to defaults
    // 2. assign any filters that are different for the new tag
    const filterChanges = PlaidReview.taggingSchemes[scheme].filters;
    return {...PlaidReview.defaultFilters, ...filterChanges};
  }

  changeTaggingScheme = (newScheme) => {
    if (!PlaidReview.taggingSchemes[newScheme]) {
      // Something went wrong
      return;
    }

    // Need to manually set selectedTaggingScheme in this render before
    // calling tagTransactions
    this.state.selectedTaggingScheme = newScheme;
    this.tagTransactions();

    this.setState({filterState: this.constructFilters(newScheme)});
  }

  tagTransactions = () => {
    if (this._getCurrentScheme().tagged) {
      // Only run engine tagging once. This way, we can jump between tagging schemes and
      // (1) make changes that persist and (2) not have to re-tag all transactions.
      return;
    }

    // add transaction tags for the selected tag only
    if (Object.keys(this.state.plaidGroups).includes(this.state.selectedTaggingScheme)) {
      if (this._getCurrentScheme().useCustomGroupings) {
        // Array here contains arrays w/ transaction IDs; unpack, creating a new dynamic
        // tag for each, then push tag to all matching txs.
        this.state.plaidGroups[this.state.selectedTaggingScheme].groupings.forEach((group, idx) => {
          let tag = this.createDynamicTag(true);
          tag.classification = group.classification;
          this.state.plaidTransactions.filter(x=>group.transactions.includes(x.transaction_id)).forEach(tx => {
            tx.transactionTags = [tag];
          })
        });
      } else {
        // Array here should just be transactions present in the grouping
        this.state.plaidTransactions.filter(x => this.state.plaidGroups[this.state.selectedTaggingScheme].groupings.transactions.includes(x.transaction_id)).forEach(txn => {
          txn.transactionTags = [this._getCurrentScheme().staticTag];
        })
      }
    }

    this._getCurrentScheme().tagged = true;
  }

  setupTaggingSchemes = () => {
    for (const [key, value] of Object.entries(PlaidReview.taggingSchemes)) {
      if (value.useCustomGroupings) {
        value.dynamicTags = [];
        value.lastDynamicTagIdx = 0;
      } else {
        value.staticTag = {
          tagName: key,
          classification: key,
        };
      }
      value.tagged = false;
    }
  }

  _getCurrentScheme = () => {
    return PlaidReview.taggingSchemes[this.state.selectedTaggingScheme];
  }

  ////////////////////////////////// Dynamic tag mgmt //////////////////////////////////

  createDynamicTag = (auto=false) => {
    let returnValue = {
      tagName: "",
      confirmed: false,
      classification: this.state.plaidGroups[this.state.selectedTaggingScheme].classification_types[0],
    };
    if (this._getCurrentScheme().useCustomGroupings) {
      returnValue.tagName = `Group ${this._getCurrentScheme().lastDynamicTagIdx++}${(auto? " (auto)" : "")}`;
      this._getCurrentScheme().dynamicTags.push(returnValue);
    }
    this.forceUpdate();
    return returnValue;
  }

  removeDynamicTag = (tag) => {
    this.state.plaidTransactions.forEach(txn => {
      txn.transactionTags = txn.transactionTags.filter(x=>x != tag);
    });
    this._getCurrentScheme().dynamicTags = this._getCurrentScheme().dynamicTags.filter(x=>x != tag);
    this.forceUpdate();
  }

  markTagAsConfirmed = (group, confirmed) => {
    group.confirmed = confirmed;
    this.forceUpdate();
  }

  _getUnconfirmedTags = () => {
    // Check that no tags are marked as "of interest" but not yet confirmed by user
    return this._getTagsOfInterest().filter(x => !x.confirmed);
  }

  _getTagsOfInterest = () => {
    return [this._getCurrentScheme().staticTag, this._getCurrentScheme().dynamicTags].flat().filter(x=>x);
  }

  render() {
    return (
      <main>
        <Navbar />
        <body class="blackbg internal">
          <div class="dashboard">
            <h1>
              Plaid Handler ID #{this.state.plaidID}
              <button
                onClick={() => {
                  window.location = `#/plaid_review`;
                  window.location.reload();
                }}
              >
                New Plaid
              </button>

              <select value={this.state.selectedTaggingScheme} onChange={(e)=>this.setState(this.changeTaggingScheme(e.target.value))}>
                {Object.keys(PlaidReview.taggingSchemes).map((element, idx) => {
                  return (
                    <option value={element}>{PlaidReview.taggingSchemes[element].displayName}</option>
                  )
                })}
              </select>
              <button onClick={this.submitClassification}
                      disabled={this.state.submitting || this._getUnconfirmedTags().length > 0}>
                Submit classification
              </button>
            </h1>
            <div class="unprocessed" id="plaid_holder">
              {this.state.plaidTransactions ? (
                <PlaidClassificationView transactions={this.state.plaidTransactions}
                                          bucketTypes={this.state.plaidGroups[this.state.selectedTaggingScheme].classification_types}
                                          selectedTag={PlaidReview.taggingSchemes[this.state.selectedTaggingScheme]}
                                          createTag={this.createDynamicTag}
                                          deleteTag={this.removeDynamicTag}
                                          confirmTag={this.markTagAsConfirmed}
                                          filterState={this.state.filterState}
                                          issueFilterUpdate={(update)=> {this.setState({filterState: {...this.state.filterState, ...update}})}} />
              ) : (
                <Loader loaded={!this.state.loading}></Loader>
              )}
            </div>
          </div>
        </body>
      </main>
    );
  };
}
