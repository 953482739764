import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../helpers/date_helper";
import { BankruptcyAction } from "../../helpers/bk_processing_helper";
import {
  lookupLoadingStatus,
  lookupErrors,
  searchedLoanIdNum,
  searchedLoanPaymentDetails,
  additionalLoans,
  mailerProcessingErrors,
  mailerProcessingLoading,
  clearMailerErrors,
  clearLookupErrors,
  openBankruptcyModal
} from "../../features/fcra/fcraSlice";
import {
  lookup,
  sendMailer
} from "../../thunks/fcraThunks";
import ConsumerInfo from "./partials/ConsumerInfo";
import AccountInfo from "./partials/AccountInfo";
import { PaymentDetails } from "./partials/PaymentDetails";
import BankruptcyActionModal from "./modals/BankruptcyActionModal";
import CloseableErrorMessage from "../CloseableErrorMessage";
import BankruptcyDetail from "./partials/BankruptcyDetail";

export default function FCRA() {
  const dispatch = useDispatch();

  const [loanId, setLoanId] = useState(null);
  const [bankruptcyAction, setBankruptcyAction] = useState("");

  const lookupLoading = useSelector(lookupLoadingStatus);
  const searchErrors = useSelector(lookupErrors);
  const searchedLoanId = useSelector(searchedLoanIdNum);
  const searchedLoanPmtDetails = useSelector(searchedLoanPaymentDetails);
  const additionalLoanDetails = useSelector(additionalLoans);

  const mailerActionLoading = useSelector(mailerProcessingLoading);
  const mailerActionErrors = useSelector(mailerProcessingErrors);

  const handleLookup = () => {
    const reportDate = formatDate(new Date());
    dispatch(lookup({ loanId, reportDate }));
  };

  const handleMailer = (e) => {
    const code = e.target.value;
    dispatch(sendMailer({ loanId, code }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLookup();
    }
  };

  const openModal = (action) => {
    setBankruptcyAction(action);
    dispatch(openBankruptcyModal());
  };

  const clearErrors = () => {
    dispatch(clearMailerErrors());
    dispatch(clearLookupErrors());
  };

  return (
    <main>
      <div className="user-show">
        <div>
          <label htmlFor="loanIdInput">Loan ID:</label>
          <input
            id="loanIdInput"
            type="text"
            value={loanId || ""}
            onChange={(e) => setLoanId(e.target.value)}
            placeholder="Enter Loan ID"
            onKeyDown={handleKeyDown}
          />
          <br />
          {lookupLoading ? (
            <span>Loading...</span>
          ) : (
            <button onClick={handleLookup}>Search</button>
          )}
          {!lookupLoading && searchedLoanId && (
            <div role="group">
              {!mailerActionLoading ? (
                <button value="103" onClick={handleMailer}>Send 103 Mailer</button>
              ) : (
                <button disabled>Sending mailer...</button>
              )}
              <button onClick={() => openModal(BankruptcyAction.FILE)}>
                File Bankruptcy
              </button>
              <button onClick={() => openModal(BankruptcyAction.DISCHARGE)}>
                Discharge Bankruptcy
              </button>
            </div>
          )}
          <br />
          <div>
            {(searchErrors || mailerActionErrors) && 
              <CloseableErrorMessage
                error={searchErrors ? searchErrors : mailerActionErrors}
                closeAction={clearErrors}
              />
            }
            {!lookupLoading && searchedLoanId && (
              <div>
                <ConsumerInfo />
                <br />
                <BankruptcyDetail />
                <AccountInfo />
              </div>
            )}
            <br />
            {!lookupLoading && searchedLoanId && searchedLoanPmtDetails && (
              <section>
                <PaymentDetails paymentDetailsObject={searchedLoanPmtDetails} />
              </section>
            )}
            <br />
            {!lookupLoading && searchedLoanId && additionalLoanDetails && (
              <section>
                <h2 id="additionalLoansHeader">
                  <b>Additional Loan Details</b>
                </h2>
                <ul className="fcra-info" style={{ padding: 0, margin: 0 }}>
                  {Object.entries(additionalLoanDetails).map(([id, loanDetails]) => (
                    <li key={id} className="payment-detail">
                      <span data-label="Loan id:">
                        {id}
                      </span>
                      <ul className="fcra-info" style={{ padding: 0, margin: 0, marginLeft: "20px" }}>
                        <li className="payment-detail" data-label="Status:">
                          {loanDetails.status}
                        </li>
                        <li className="payment-detail" data-label="Current balance:">
                          ${loanDetails.current_balance}
                        </li>
                        <li className="payment-detail" data-label="Accepted:">
                          {loanDetails.accepted}
                        </li>
                        <li className="payment-detail" data-label="Cash advanced:">
                          ${loanDetails.cash_advanced}
                        </li>
                        <PaymentDetails paymentDetailsObject={loanDetails.payment_detail} />
                      </ul>
                    </li>
                  ))}
                </ul>
              </section>
            )}
          </div>
          <BankruptcyActionModal
            bankruptcyAction={bankruptcyAction}
          />
          <br />
        </div>
      </div>
    </main>
  );
}