import React, { Component } from "react";

export default class TransactionFilterView extends Component {

  render() {
    return (
      <div class="filter_panel">
        <h2>Filters</h2>
        Name: <input type="field"
          placeholder="Filter..."
          value={this.props.filterState.nameFilter}
          onChange={e => this.props.mutateState({nameFilter: e.target.value})}/>
        <br/>
        Amount: <input type="text"
          size="5"
          placeholder="-∞"
          value={this.props.filterState.minAmount.length == 0 ? "" : this.props.filterState.minAmount}
          onChange={e => this.props.mutateState({minAmount: e.target.value})}/> &#60;= x &#60;=&nbsp;
        <input type="text"
          size="5"
          placeholder="∞"
          value={this.props.filterState.maxAmount.length == 0 ? "" : this.props.filterState.maxAmount}
          onChange={e => this.props.mutateState({maxAmount: e.target.value})}/>
        <br/>
        Show Grouped: <input type="checkbox"
          checked={this.props.filterState.showGrouped}
          onChange={e => this.props.mutateState({showGrouped: e.target.checked})}/>
      </div>
    );
  }
}